import React from 'react';

/*
const DownloadExcelFile = ({tableRows}) => {
  return <></>;
};
*/

import * as XLSX from 'xlsx';

const DownloadExcelFile = ({tableRows}) => {
  // Sample data for the Excel file
  const data = [[
    'DataMov',
    'item_code',
    'item_desc',
    'NC',
    'TipoDocReg',
    'NumDocReg',
    'DataDocReg',
    'quantity_value_kg',
    'TipoMov',
    'PercentTaxedReg',
    'quantity_kg_reg',
    'counterpart_desc_reg',
    'CodPaese',
    'p_iva_reg',
    'ADM_olluda',
    'TipoVendita',
    'intracee_tag',
    'IC',
    'CONOU',
    'CausaleMovimento',
    'PosFiscale',
  ]];

  for (var record of tableRows) {
    var row = []
    row.push(record.DataMov);
    row.push(record.item_code);
    row.push(record.item_desc);
    row.push(record.NC);    
    row.push(record.TipoDocReg);
    row.push(record.NumDocReg);
    row.push(record.DataDocReg);
    row.push(record.quantity_value_kg);
    row.push(record.TipoMov);
    row.push(record.PercentTaxedReg);
    row.push(record.quantity_kg_reg);
    row.push(record.counterpart_desc_reg);
    row.push(record.CodPaese);
    row.push(record.p_iva_reg);
    row.push(record.ADM_olluda);
    row.push(record.TipoVendita);
    row.push(record.intracee_tag);
    row.push(record.IC);
    row.push(record.CONOU);
    row.push(record.CausaleMovimento);
    row.push(record.PosFiscale);
 
    data.push(row);
    }


  const downloadExcelFile = () => {
    // Create a new Excel workbook
    const workbook = XLSX.utils.book_new();

    // Add a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel file buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Convert the buffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'olluda.xlsx');

    // Simulate a click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button onClick={downloadExcelFile}>Download Excel File</button>
    </div>
  );
};


export default DownloadExcelFile;