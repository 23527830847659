import React from 'react';
import {Link, Route, Routes } from 'react-router-dom';

import Users from './Users';
import User from './User';

function UsersMain() {
return (
<>
<Link className='mx-2' to={'/users'}>Users</Link>
    
<Routes>
    <Route path="user/:UserID/*" element={<User />} />
    <Route index element={<Users />} />
    <Route path="*" element={<Users />} />
</Routes>

</>

);

}

export default UsersMain;
