import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, removeUserSession, getToken } from '../utils/common';

const Dashboard = props => {
  const history = useNavigate();
  const visible = getToken() ? true : false;  
  const user = getUser();
  const access = user && user.access;

  const handleLogout = () => {
    removeUserSession();
    window.location.reload();
  }

  return (
    <div>
      Logged in as {user && <>{user.FirstName} {user.LastName} ({user.UserID})</> }<br /><br />
      <input className = 'm-2' type="button" onClick={handleLogout} value="Logout" />
      <input className={`m-2 ${visible && access.hasOwnProperty('movimenti') ? 'd-all' : 'd-none'}`} type="button" onClick={() => {history('/movimenti')}} value="Movimenti" />
      <input className={`m-2 ${visible && access.hasOwnProperty('energetici') ? 'd-all' : 'd-none'}`} type="button" onClick={() => {history('/energetici')}} value="Energetici" />
      <input className={`m-2 ${visible && access.hasOwnProperty('users') ? 'd-all' : 'd-none'}`} type="button" onClick={() => {history('/users')}} value="Utenti" />
    </div>
  );
}

export default Dashboard;