import React, { useState } from 'react';
import './Olluda.css'; // Import CSS file for styling

function numberToFixedWithTrailingZeros(num, decimalPlaces) {
  
  if (num == null) return null
  if (!(typeof num == 'number')) return null

  const fixedNum = num.toFixed(decimalPlaces);
  const [integerPart, decimalPart] = fixedNum.split('.');
  let result = integerPart;
  
  if (decimalPlaces > 0) {
      // Add decimal point
      result += '.';
      
      // Add decimal part
      if (decimalPart) {
          result += decimalPart;
      } else {
          // Add trailing zeros
          result += '0'.repeat(decimalPlaces);
      }
  }
  
  return result;
}



const CreateTextFile = ({tableRows, cali}) => {

  const [formData, setFormData] = useState({
    type: 'I',
    number: 1,
    month: 4,
    day: 1,
    AnnoRif: 2024,
    MeseRif: 3
  });

  const typeOptions = [
    'I',
    'C',
  ];




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateFileContent = (tableRows) => {
    const { type, number} = formData;
    var output = '';
    
//    output += '\n' + tableRows.length + '\n'
//    output += cali.length+'\n'

    const format_record_B_1 = [
    [1, "FlussoDati",               1,  "X", 6],
    [2, "CodiceAccisa",         7,  "X", 13],
    [3, "DataMovimento",        20, "9", 8],
    [4, "TipoRecord",           28, "X", 1], 
    [5, "TipoRichiesta",        29, "X", 1], 
    ];

    const format_record_B_2 = [
      [6, "TipoRegistro",         30, "X", 1], 
      [7, "CodiceUfficio",        38, "X", 8], 
      [8, "AnnoRegistro",         38, "9", 4], 
      [9, "NumRegistro",          42, "X", 10], 
      [10, "NumRecord",           52, "9", 7], 
      [11, "NC",                  59, "X", 18], 
      [12, "Assente1",            77, "X", 1], 
      [13, "quantity_kg_reg",     78, "V", 3], 
      [14, "QuantitaLt",          78, "V", 3], 
      [15, "Densita",             78, "V", 4], 
      [16, "TipoConfezione",      78, "X", 1], 
      [17, "QuantitaNominaleConf",78, "V", 3], 
      [18, "NumConfezioni",       78, "9", 7], 
      [19, "TipoDocReg",             78, "X", 3], 
      [20, "NumDocReg",              78, "X", 21], 
      [21, "DataDocReg",             78, "9", 8], 
      [22, "NumDAS_coll_X_F",     78, "X", 21], 
      [23, "CodPaese",            78, "X", 2], 
      [24, "p_iva_reg",       78, "X", 30], 
      [25, "TipoMov",             78, "X", 1], 
      [26, "CausaleMovimento",    78, "9", 3], 
      [27, "PosFiscale",          78, "9", 3], 
      [28, "IC",                  78, "V", 2], 
      [29, "CONOU",               78, "V", 2], 
      [30, "Assente2",            78, "X", 1], 
      [31, "Assente3",            78, "X", 1], 
      [32, "Assente4",            78, "X", 1], 
      [33, "Assente5",            78, "X", 1], 
      [34, "Assente6",            78, "X", 1], 
      [35, "Assente7",            78, "X", 1], 
      [36, "Assente8",            78, "X", 1], 
      [37, "NoteRegADM",                78, "X", 800], 
      ];

      const format_record_A = [
        [1, "FlussoDati",1,     "X", 6],
        [2, "CodiceAccisa",     7,  "X", 13],
        [3, "ValoreFisso1",     20, "9", 8],
        [4, "TipoRecord",       28, "X", 1], 
        [5, "CodiceFiscale",    29, "X",16], 
        [6, "B_count",      45, "9",5], 
        [7, "D_count",      50, "9",5], 
        [8, "E_count",      55, "9",5], 
        [9, "ValoreFisso2",     60, "9",5], 
        [10, "R_count",     65, "9",5], 
        [11, "NumeroRecord",    70, "9",6],
        ];       

  
    var NumRecord = 0
    var IC = 0

    for (var row of tableRows)
    {
      
        output += row.FlussoDati
        output += row.CodiceAccisa
        output += row.hasOwnProperty('DataMov') ? row.DataMov.replaceAll("-", "") : ''
        output += 'B'
        output += type
        output += '\t'

        for (var f of format_record_B_2) {

          if (f[1] == 'NumRecord') {
            NumRecord += 1
            output += NumRecord
        }

          else if (f[1] == 'quantity_kg_reg') {
              output += numberToFixedWithTrailingZeros(row[f[1]], 3)
          }
          else if (f[1] == 'IC') {
            if (row[f[1]] == null) output+=''
            else {
              output += numberToFixedWithTrailingZeros(row[f[1]], 2);
              IC += row[f[1]]  
            }
        }
        else if (f[1] == 'CONOU') {
          if (row[f[1]]==0.0 || row[f[1]]==null) output+='';
          else output += numberToFixedWithTrailingZeros(row[f[1]], 2);
        }
        else if (f[1] == 'DataDocReg' ) {
            output += row.hasOwnProperty(f[1]) ? row[f[1]].replaceAll("-", "") : ''
        }

        else if (row.hasOwnProperty(f[1])) {
            output += (row[f[1]] == null ? '' : row[f[1]]);
          }
        
          output += '\t'          
        }
          /*

        output += row.TipoRegistro +'\t'
        output += row.CodiceUfficio +'\t'
        output += row.AnnoRegistro +'\t'
        output += row.NumRegistro +'\t'
          */
        output += '\n'
    }


    for (var row of cali)
    {
        console.log(row)

        output += row.FlussoDati
        output += row.CodiceAccisa
        output += row.hasOwnProperty('DataMov') ? row.DataMov.replaceAll("-", "") : ''
        output += 'B'
        output += type
        output += '\t'

        for (var f of format_record_B_2) {

          if (f[1] == 'NumRecord') {
            NumRecord += 1
            output += NumRecord
        }

          else if (f[1] == 'quantity_kg_reg') {
              output += numberToFixedWithTrailingZeros(row['calo_kg_reg'], 3)
          }
          else if (f[1] == 'TipoMov') {
            output += 'S'
        }
        else if (f[1] == 'CausaleMovimento') {
          output += '009'
      }


          else if (f[1] == 'IC') {
            if (row[f[1]] == null) output+=''
            else {
              output += numberToFixedWithTrailingZeros(row[f[1]], 2);
              IC += row[f[1]]  
            }
        }
        else if (f[1] == 'CONOU') {
          if (row[f[1]]==0.0 || row[f[1]]==null) output+='';
          else output += numberToFixedWithTrailingZeros(row[f[1]], 2);
        }
        else if (f[1] == 'DataDocReg' ) {
            output += row.hasOwnProperty(f[1]) ? row[f[1]].replaceAll("-", "") : null;
        }

        else if (row.hasOwnProperty(f[1])) {
            output += (row[f[1]] == null ? '' : row[f[1]]);
          }
        
          output += '\t'          
        }
          /*

        output += row.TipoRegistro +'\t'
        output += row.CodiceUfficio +'\t'
        output += row.AnnoRegistro +'\t'
        output += row.NumRegistro +'\t'
          */
        output += '\n'
    }





    if (IC > 0) {

    const format_record_E = [
      [1, "FlussoDati",1,     "X", 6],
      [2, "CodiceAccisa",     7,  "X", 13],
      [3, "ValoreFisso1",     20, "9", 8],
      [4, "TipoRecord",       28, "X", 1], 
      [5, "TipoRichiesta",    29, "X",1], 
      [6, "AnnoRif",          45, "9",4], 
      [7, "MeseRif",       45, "9",2], 
      [8, "Tributi_debito",   45, "V",2], 
      [9, "Crediti_utilizzati",45,"V",2], 
      [10, "Storni"           ,45,"V",2], 
      [11, "Tributi_saldo"    ,45,"V",2], 
      [12, "Capitolo_tributi" ,45,"X",5], 
      [13, "Note",            70, "X",800],
      ];

    var recordE = ''
    recordE += 'OLLUDA'
    recordE += 'IT00BGB00003E'
    recordE += '00000000'
    recordE += 'E'
    recordE += type
    recordE += '\t'
    recordE += formData.AnnoRif /* Anno Rif */
    recordE += '\t'
    recordE += String(formData.MeseRif).padStart(2, '0') /* Mese Rif */
    recordE += '\t'
    recordE += numberToFixedWithTrailingZeros(IC, 2) /* Tributi debito */
    recordE += '\t'
    recordE += '0.00' /* Crediti utilizzati*/
    recordE += '\t'
    recordE += '0.00' /* Storni */
    recordE += '\t'
    recordE += numberToFixedWithTrailingZeros(IC, 2) /* Tributi saldo */
    recordE += '\t'
    recordE += '1431' /* Capitolo */
    recordE += '\t'
    recordE += '' /* Note */
    recordE += '\n'
    output += recordE
  }

    
    var recordA = ''
    recordA += 'OLLUDA'
    recordA += 'IT00BGB00003E'
    recordA += '00000000'
    recordA += 'A'
    recordA += '06260440158'
    recordA += '\t'
    recordA += NumRecord /* B Count */
    recordA += '\t'
    recordA += '0' /* D Count */
    recordA += '\t'
    recordA += IC > 0 ? 1 : 0 /* E Count */
    recordA += '\t'
    recordA += '0' /* Valore fisso */
    recordA += '\t'
    recordA += '0' /* R count */
    recordA += '\t'
    recordA += NumRecord + 1 + (IC > 0 ? 1 : 0)/* T count */
    recordA += '\n'

    output = recordA + output

    const format_record_di_testa = [
      [1, "CodiceMittente",       1,  "X", 4],
      [2, "riservato_SDA1",   5,  "X",12],
      [3, "filename",       17, "X",12],
      [4, "riservato_SDA2",   29, "X",12], 
      [5, "SezioneDoganale",  41, "9",6], 
      [6, "riservato_SDA3",   47, "X",4], 
      [7, "CodiceFiscale",    51, "X",16], 
      [8, "ProgressivoSede",67,   "9",3], 
      [9, "riservato_SDA4",   70, "X",1], 
      [10, "NumeroRecord",    71, "9",5],
      ];

    const filename = `1D31${String(formData.month).padStart(2, '0')}${String(formData.day).padStart(2, '0')}.C${String(formData.number).padStart(2, '0')}`;
    var recordH = ''
    recordH += '1D31'  
    recordH += String('').padEnd(12, ' ')
    recordH += String(filename).padEnd(12, ' ')  
    recordH += String('').padEnd(12, ' ')  
    recordH += String('276000').padEnd(6, ' ')  
    recordH += String('').padEnd(4, ' ')  
    recordH += String('06260440158').padEnd(16, ' ')  
    recordH += String('001').padEnd(3, ' ')  
    recordH += String('').padEnd(1, ' ')  
    recordH += String(NumRecord + 2 + (IC > 0 ? 1 : 0)).padStart(3, '0').padEnd(5, ' ') 
    recordH += '\n'


    output = recordH + output
    
    return output 
    
//    return `Type: ${type}\nNumber: ${number}`;
  };

  const handleDownload = (e) => {
    e.preventDefault();
    const fileContent = generateFileContent(tableRows);
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `1D31${String(formData.month).padStart(2, '0')}${String(formData.day).padStart(2, '0')}.C${String(formData.number).padStart(2, '0')}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
    <form onSubmit={handleDownload} className="form-container">
        <div className="input-container">
          <label htmlFor="number">File Number:</label>
          <input
            type="number"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="type">Type:</label>
          <select
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
          >
            {typeOptions.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <label htmlFor="day">Giorno:</label>
          <input
            type="number"
            id="day"
            name="day"
            value={formData.day}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="month">Mese:</label>
          <input
            type="number"
            id="month"
            name="month"
            value={formData.month}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="AnnoRif">AnnoRif:</label>
          <input
            type="number"
            id="AnnoRif"
            name="AnnoRif"
            value={formData.AnnoRif}
            onChange={handleChange}
          />
        </div>
        <div className="input-container">
          <label htmlFor="MeseRif">MeseRif:</label>
          <input
            type="number"
            id="MeseRif"
            name="MeseRif"
            value={formData.MeseRif}
            onChange={handleChange}
          />
        </div>



        <button type="submit">Download Text File</button>
      </form>
    </div>
  );
};

export default CreateTextFile;
