import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';
import './Olluda.css'; // Import CSS file for styling
import {getToken} from '../utils/common.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { BlobProvider } from '@react-pdf/renderer';
import { PDFDocument} from 'pdf-lib';


import CreateTextFile from './CreateTextFile.js';
import DownloadExcelFile from './CreateExcelFile.js';
import RegistroMensileOlluda from  '../olluda/RegistroMensileOlluda.js' 

const IC = 0.78781;
const CONOU = 0.07;

const LinkToRegistro = ({tableRows}) => {
    const [pdfDoc, setPdfDoc] = useState(null);
  
    const handleBlob = async (blob) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const arrayBuffer = reader.result;
        const pdfDocument = await PDFDocument.load(arrayBuffer);
        setPdfDoc(pdfDocument);
      };
      reader.readAsArrayBuffer(blob);
    };
  
    return (
      <BlobProvider document={<RegistroMensileOlluda tableRows = {tableRows} />} documentTitle="RegistroMensileOlluda.pdf">
        {({ blob }) => {
          if (!pdfDoc && blob) {
            handleBlob(blob);
          }
  
          return (
            <span className ="mx-3" >
              {pdfDoc && (<FontAwesomeIcon icon={faFilePdf} style = {{color: "red"}} onClick={async () => {
                  // Save the modified PDF as bytes
                  const modifiedPdfBytes = await pdfDoc.save();
  
                  // Open the modified PDF in a new tab
                  const pdfDataUri = URL.createObjectURL(new Blob([modifiedPdfBytes], { type: 'application/pdf' }));
                  window.open(pdfDataUri, '_blank');
                }} />) }
  
            </span>
          );
        }}
      </BlobProvider>
    );
  };
  
const Content = ({ formData }) => {

    const [isLoading, setLoading] = useState(false);
    const [response, setResponse] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [rows, setRows] = useState(0);
    const [row, setRow] = useState(0);
    const [progress, setProgress] = useState(0);
    const [totaleCarichiKG, setTotaleCarichiKG] = useState(0.0);
    const [totaleCarichiIC, setTotaleCarichiIC] = useState(0.0);
    const [totaleScarichiKG, setTotaleScarichiKG] = useState(0.0);
    const [totaleScarichiSIC, setTotaleScarichiSIC] = useState(0.0);    
    const [totaleScarichiIC, setTotaleScarichiIC] = useState(0.0);
    const [GiacenzaInizialeKG, setGiacenzaInizialeKG] = useState(0.0);
    const [GiacenzaFinaleKG, setGiacenzaFinaleKG] = useState(0.0);
    const [SaldoMovimentiKG, setSaldoMovimentiKG] = useState(0.0);
    const [IC_EUR, setIC_EUR] = useState(0.0);
    const [CONOU_EUR, setCONOU_EUR] = useState(0.0);
    const [cali, setCali] = useState([]);
 
    const fetchData = async () => {
        try {
            setLoading(true);
            setTableRows([])
            setCali([])
            setTotaleCarichiIC(0);
            setTotaleCarichiKG(0);
            setTotaleScarichiIC(0);
            setTotaleScarichiSIC(0);
            setTotaleScarichiKG(0);
            setSaldoMovimentiKG(0);
            setGiacenzaFinaleKG(0);
            setGiacenzaInizialeKG(0);
            setRow(0);

            const API = process.env.REACT_APP_BACKEND_URL;
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getToken(),
                },
            };

            const response = await axios.post(`${API}/api/olluda/movimenti/`, formData, options);
            setResponse(response.data.data);
            setRows(response.data.data.length)
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchGiacenzaIniziale = async () => {
        try {
    
            const API = process.env.REACT_APP_BACKEND_URL;
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getToken(),
                },
            };
            var request = formData;
            if (formData.RootCode == 'BG_ASSOLTA') request['Property'] = 'GiacenzaIniziale';
            if (formData.RootCode == 'BG_MPSIC') request['Property'] = 'GiacenzaIniziale';
            if (formData.RootCode == 'BG_PFSIC') request['Property'] = 'GiacenzaInizialeTassata';
            if (formData.RootCode == 'BG_ADDITIVI') request['Property'] = 'GiacenzaInizialeTassata';

            const response = await axios.post(`${API}/api/olluda/giacenza/get/`, request, options);
            console.log(response.data);
            setGiacenzaFinaleKG(response.data.Value);
            setGiacenzaInizialeKG(response.data.Value);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchData();
        fetchGiacenzaIniziale();
    }, [formData]);

    useEffect(() => {
        if(rows >0) setProgress(Math.round(((row) / rows) * 100));
    }, [row]);

    function quantity_value_registro(item) {

        if (item.ADM_olluda == 'BG_ASSOLTA' ) return 1.0 * item.quantity_value_kg 
        if (item.ADM_olluda == 'BG_MPSIC' ) return 1.0 * item.quantity_value_kg
        if (item.ADM_olluda == 'BG_ADDITIVI' || item.ADM_olluda == 'BG_PFSIC' )
        { 
            if (item.percent_taxed_ADM) return item.percent_taxed_ADM/100 * item.quantity_value_kg
            if (item.percent_taxed) return item.percent_taxed/100 * item.quantity_value_kg
            return 0
        } 
        return 0

    }

    function addIC(item) {

        if (item.ADM_olluda == 'BG_ASSOLTA' ) return 0.0
        if (item.ADM_olluda == 'BG_MPSIC' ) return 0.0
        if (item.ADM_olluda == 'BG_ADDITIVI' ) return 0.0

        if (item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'A' && item.movement_type_code == 'shipment')
        { 
            return parseFloat((-quantity_value_registro(item) * IC).toFixed(2))
        } 

        return 0.0
    }

    function addCONOU(item) {

        if (item.ADM_olluda == 'BG_ASSOLTA' ) return 0.0
        if (item.ADM_olluda == 'BG_MPSIC' ) return 0.0
        if (item.ADM_olluda == 'BG_ADDITIVI' ) return 0.0

        if (item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'A' && item.movement_type_code == 'shipment')
        { 
            return parseFloat((-quantity_value_registro(item) * CONOU).toFixed(2))
        } 
        return 0.0
    }


    useEffect(() => {

        setRow(0);
        const fetchDetails = async () => {
            try {
                const API = process.env.REACT_APP_BACKEND_URL;
                const maxConcurrentRequests = 50; // Maximum number of requests to send in parallel
                const chunkSize = Math.ceil(response.length / maxConcurrentRequests);

                const chunks = [];
                for (let i = 0; i < response.length; i += chunkSize) {
                    chunks.push(response.slice(i, i + chunkSize));
                }

                // Map each chunk to a Promise.all() call
                const promises = chunks.map(chunk =>
                    Promise.all(
                        chunk.map(async (item, index) => {

                            const options = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': getToken(),
                                },
                            };

                            const details = await axios.post(`${API}/api/olluda/movimenti/dettagli/`, item, options);
                            const new_row = details.data.data;
                            try {
                            if (new_row.movement_type_code === 'load' || new_row.movement_type_code === 'adjustment_up') {
                                setTotaleCarichiKG(prevValue => prevValue + parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                setSaldoMovimentiKG(prevValue => prevValue + parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                setGiacenzaFinaleKG(prevValue => prevValue + parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                setTotaleCarichiIC(prevValue => prevValue + parseFloat((quantity_value_registro(new_row) * IC).toFixed(2)));
        
                            } else if (new_row.movement_type_code === 'unload' || new_row.movement_type_code === 'adjustment_down' || new_row.movement_type_code === 'shipment') {
//                                setTotaleScarichiKG(prevValue => prevValue - new_row.quantity_value_kg);
                                if(new_row.TipoVendita == 'S')
                                    setTotaleScarichiSIC(prevValue => prevValue - parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                else    setTotaleScarichiKG(prevValue => prevValue - parseFloat(quantity_value_registro(new_row).toFixed(3)));

                                if(new_row.TipoVendita != 'S')
                                    setTotaleScarichiIC(prevValue => prevValue - parseFloat((quantity_value_registro(new_row) * IC).toFixed(2)));

                                setSaldoMovimentiKG(prevValue => prevValue + parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                setGiacenzaFinaleKG(prevValue => prevValue + parseFloat(quantity_value_registro(new_row).toFixed(3)));
                                setIC_EUR(prevValue => prevValue + addIC(new_row))
                                setCONOU_EUR(prevValue => prevValue + addCONOU(new_row))
        
                            }
                            } catch(error) {console.log("237", error, new_row)}
                           setRow(row => row + 1);      
                            return new_row;
                        })
                    )
                );

                // Resolve each set of promises sequentially
                for (const promiseSet of promises) {
                    const results = await promiseSet;
//                    console.log(results.length, results)
                    setTableRows(prevRows => [...prevRows, ...results]);
 //                   console.log(tableRows.length, tableRows);

                }
            } catch (error) {
                console.log('Error fetching details:', error);
            }
        };

        if (response.length > 0) {
            fetchDetails();
        }
    }, [response]);

    useEffect(() => {

    if (progress >= 100) {

        for (var r of tableRows) {
            if (r.calo_in_registro == 1 && r.item_code == r.target_item_code)
            {
                var c = cali
                c.push(r)
                setCali(c);
            }
        }
    }
    }, [progress])
    
    if (isLoading) {
        return <Spinner className='m-5' animation="border" variant="primary" />;
    }

    return (
        <>
            {progress < 100 ? <><ProgressBar now={progress} label={`${progress}%`} />
            <h5>{row}/{rows}</h5></> : null}
            {progress >= 100 ? <><CreateTextFile tableRows = {tableRows} cali={cali}/> <DownloadExcelFile tableRows={tableRows} cali={cali}/> <LinkToRegistro tableRows={tableRows} cali={cali}/></> : null}
            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-light" >
              <th>N</th>
              <th>Data Mov</th>
              <th>Tipo Doc</th>
              <th>Num Doc</th>
              <th>Data Doc</th>
              <th>Controparte</th>
              <th>Cod Art.</th>
              <th>Mov/tax</th>
              <th>Carico Naz. Kg</th>
              <th>Carico Naz. IC €</th>
              <th>Scarico Naz. Kg</th>
              <th>Scarico Naz. IC €</th>
              <th>Scarico SIC Kg</th>
              <th>Carico CEE Kg</th>
              <th>Carico CEE IC €</th>
              <th>Scarico CEE Kg</th>
              <th>Scarico CEE IC €</th>
              <th>IC CONOU €</th>
              <th>+</th>
            </tr>
            <tr className="table-dark" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Giacenza iniziale</th>
              <th></th>
              <th>{GiacenzaInizialeKG && GiacenzaInizialeKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null: (IC*GiacenzaInizialeKG).toFixed(2)}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            </thead>
                <tbody>
                {tableRows.map((item, index) => <TableRow item = {item} index={index}/>)}
                </tbody>
            <thead>
            <tr className="table-light" >
              <th>N</th>
              <th>Data Mov</th>
              <th>Tipo Doc</th>
              <th>Num Doc</th>
              <th>Data Doc</th>
              <th>Controparte</th>
              <th>Cod Art.</th>
              <th>Mov/tax</th>
              <th>Carico Naz. Kg</th>
              <th>Carico Naz. IC €</th>
              <th>Scarico Naz. Kg</th>
              <th>Scarico Naz. IC €</th>
              <th>Scarico SIC Kg</th>
              <th>Carico CEE Kg</th>
              <th>Carico CEE IC €</th>
              <th>Scarico CEE Kg</th>
              <th>Scarico CEE IC €</th>
              <th>IC CONOU €</th>
              <th>+</th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Totale movimenti nel mese</th>
              <th></th>
              <th>{totaleCarichiKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null: totaleCarichiIC.toFixed(2)}</th>
              <th>{totaleScarichiKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null: totaleScarichiIC.toFixed(2)}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th><p className='my-0'>{IC_EUR.toFixed(2)}</p><p className='my-0'>{CONOU_EUR.toFixed(2)}</p></th>
              <th></th>

            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Saldo movimenti nel mese</th>
              <th></th>
              <th>{SaldoMovimentiKG && SaldoMovimentiKG >= 0 && SaldoMovimentiKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null : SaldoMovimentiKG && SaldoMovimentiKG >= 0 && (IC*SaldoMovimentiKG).toFixed(2)}</th>
              <th>{SaldoMovimentiKG && SaldoMovimentiKG < 0 && (-SaldoMovimentiKG).toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null : SaldoMovimentiKG && SaldoMovimentiKG < 0 && (-IC*SaldoMovimentiKG).toFixed(2)}</th>
              <th></th>
              <th></th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Giacenza Iniziale</th>
              <th></th>
              <th>{GiacenzaInizialeKG && GiacenzaInizialeKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null: (IC*GiacenzaInizialeKG).toFixed(2)}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Carichi SIC nel mese</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Scarichi con pagamento IC</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Scarichi in SIC</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null : totaleScarichiSIC.toFixed(3)}</th>
            </tr>
            <tr className="table-light" >
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Giacenza Finale</th>
              <th></th>
              <th>{GiacenzaFinaleKG && GiacenzaFinaleKG.toFixed(3)}</th>
              <th>{formData.RootCode == 'BG_ASSOLTA' ? null: (IC*GiacenzaFinaleKG).toFixed(2)}</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>

            </thead>

            </table>            
        </>
    );
};

const TableRow = ({item, index}) => {

    const [additionalDataVisible, setAdditionalDataVisible] = useState(false);
      function switchAdditionalDataVisible () {
        setAdditionalDataVisible(!additionalDataVisible);
      }
    

/*
      function getCausaleMovimento(item) {

        if (item.protocol_type_code == 'moiddt' && item.ADM_olluda == 'BG_ASSOLTA' ) return '016' 
        if (item.protocol_type_code == 'moiddt' && item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'S') return '016' 
        if (item.protocol_type_code == 'moiddt' && item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'A') return '024' 

        if (item.protocol_type_code == 'mppri') return '107' 

        if (item.protocol_type_code == 'moina' && (item.movement_type_code == 'load' || item.movement_type_code == 'adjustment_up')) return '109' 
        if (item.protocol_type_code == 'moina' && (item.movement_type_code == 'unload' || item.movement_type_code == 'adjustment_down')) return '030' 

    }

    function getIC(item) {

        if (item.protocol_type_code == 'moiddt' && item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'A') 
            return (-percentTaxed(item) * item.quantity_value_kg * IC).toFixed(2)
        return '' 
    }

    function getCONOU(item) {

        if (item.protocol_type_code == 'moiddt' && item.ADM_olluda == 'BG_PFSIC' && item.TipoVendita == 'A') 
            return (-percentTaxed(item) * item.quantity_value_kg * CONOU).toFixed(2)
        return '' 
    }


    function percentTaxed(item) {
        
        if (item.ADM_olluda == 'BG_ASSOLTA' ) return 1.0 
        if (item.ADM_olluda == 'BG_MPSIC' ) return 1.0 
        if (item.ADM_olluda == 'BG_ADDITIVI' || item.ADM_olluda == 'BG_PFSIC' )
        { 
            if (item.percent_taxed_ADM) return item.percent_taxed_ADM/100
            if (item.percent_taxed) return item.percent_taxed/100
            return 0
        } 
        return 0
    }


    function getPosFiscale(item) {

        if ((item.movement_type_code == 'load' || item.movement_type_code == 'adjustment_up')) {
            if (item.ADM_olluda == 'BG_ASSOLTA' ) return '1' 
            if (item.ADM_olluda == 'BG_MPSIC' ) return '2' 
            if (item.ADM_olluda == 'BG_ADDITIVI' ) return '2' 
            if (item.ADM_olluda == 'BG_PFSIC' ) return '2' 

        }

        if ((item.movement_type_code == 'unload' || item.movement_type_code == 'adjustment_down' || item.movement_type_code == 'shipment')) {
            if (item.ADM_olluda == 'BG_ASSOLTA' ) return '1' 
            if (item.ADM_olluda == 'BG_MPSIC' ) return '2' 
            if (item.ADM_olluda == 'BG_ADDITIVI' ) return '2' 
            if (item.ADM_olluda == 'BG_PFSIC' ) {
                if (item.TipoVendita == 'A') return '1' 
                if (item.TipoVendita == 'S') return '2' 
            }
        }

        return ''
    }


      function getDataMov(item) {

        if (item.hasOwnProperty('DataMov_ADM') && item.DataMov_ADM) return item.DataMov_ADM 
        if (item.hasOwnProperty('is_VIA') && item.is_VIA == 1) return item.date_completed 
        return item.timestamp.split('T')[0];
    }


    var TipoDoc = item.TipoDoc ? item.TipoDoc : (item.hasOwnProperty('is_VIA') && item.is_VIA == 1 ? 'VIA' : 'TBD');
    
    function getNumDoc(item) {
        
        if (item.hasOwnProperty('NumDoc') && item.NumDoc) return item.NumDoc 
        if (item.hasOwnProperty('is_VIA') && item.is_VIA == 1) return item.VIA_number 

    }

    function getDataDoc(item) {
        
        if (item.hasOwnProperty('DataDoc') && item.DataDoc) return item.DataDoc 
        if (item.hasOwnProperty('is_VIA') && item.is_VIA == 1) return item.VIA_date 
        if (item.protocol_type_code == 'mppri') return item.ddt_date 
        if (item.protocol_type_code == 'moina') return item.date_completed 
        if (item.protocol_type_code == 'moiddt') return item.timestamp.split('T')[0]; 

        return '--'
    }

    function getControparte(item) {
        
        if (item.protocol_type_code == 'moiddt') return <><p className = "my-0">{item.p_iva}</p><p className = "my-0">{item.client_desc}</p></>;
        if (item.protocol_type_code == 'mppri') return <><p className = "my-0">{item.p_iva}</p><p className = "my-0">{item.supplier_desc}</p></>;

        return <>---</> 

        return '--'
    }

    function getItem(item) {
        
        return <>
        <p className = "my-0">{item.item_code}</p>
        <p className = "my-0">{item.item_desc}</p>
        <p className = "my-0">NC: {item.NC}</p>        
        </>;

        return <>---</> 

        return '--'
    }




    function getMov(item) {
        
        return <>
        <p className = "my-0">{item.quantity_value_kg}</p>
        <p className = "my-0">{item.percent_taxed_ADM}%</p>
        <p className = "my-0">{item.percent_taxed ? item.percent_taxed.toString() + '%' : null}</p>
        {(item.ADM_olluda == 'BG_PFSIC') && <p className = "my-0">Vendita: {item.TipoVendita} CEE: {item.intracee} </p>}
        <p className = "my-0">Caus. Mov. {item.CausaleMovimento} p.f. {item.PosFiscale}</p>

        </>;


    }

    function getCarico(item) {
        
        if (item.movement_type_code == 'load'  || item.movement_type_code == 'adjustment_up')  
            return <><p className = "my-0">{(item.quantity_value_kg*percentTaxed(item)).toFixed(3)}</p></>;

        return <></> 

    }

    function getCaricoIC(item) {

        if (item.ADM_olluda == 'BG_ASSOLTA') return <></>;

        if (item.movement_type_code == 'load' || item.movement_type_code == 'adjustment_up')  
            return <><p className = "my-0">{(item.quantity_value_kg * percentTaxed(item)* 0.78781).toFixed(2)}</p></>;

            return <></>; 

    }


    function getScarico(item) {

        if (item.TipoVendita != 'S')
        if (item.movement_type_code == 'unload' || item.movement_type_code == 'shipment' || item.movement_type_code == 'adjustment_down'  )  
            return <><p className = "my-0">{(-item.quantity_value_kg*percentTaxed(item)).toFixed(3)} {item.TipoVendita}</p></>;

        return <></>; 
    }

    function getScaricoSIC(item) {
        
        if (item.TipoVendita == 'S')
        if (item.movement_type_code == 'unload' || item.movement_type_code == 'shipment' || item.movement_type_code == 'adjustment_down'  )  
            return <><p className = "my-0">{(-item.quantity_value_kg*percentTaxed(item)).toFixed(3)} {item.TipoVendita}</p></>;

        return <></>; 
    }


    function getScaricoIC(item) {

        if (item.TipoVendita == 'S')  return <></>;

        if (item.ADM_olluda == 'BG_ASSOLTA') return <></>;
        
        if (item.movement_type_code == 'unload'  || item.movement_type_code == 'shipment' || item.movement_type_code == 'adjustment_down' )  
            return <><p className = "my-0">{(-item.quantity_value_kg * percentTaxed(item) * IC).toFixed(2)}</p></>;
        return <></> 

    }
*/
    useEffect(() => {}, [additionalDataVisible]);

    return (
        <><tr key = {index}>
            <td>{item.NumIntDoc}</td>    
            <td>{item.DataMov}</td>    
            <td>{item.TipoDocReg}</td>    
            <td>{item.NumDocReg}</td>    
            <td>{item.DataDocReg}</td>    
            <td><p className='my-0'>{item.CodPaese} {item.p_iva_reg}</p><p className='my-0'>{item.counterpart_desc_reg}</p><p className='my-0'>{item.intracee_tag} {item.TipoVendita_tag}</p></td>    
            <td>
            <p className = "my-0">{item.item_code}</p>
            <p className = "my-0">{item.item_desc}</p>
            <p className = "my-0">NC: {item.NC}</p>        
            </td>
            <td>   
            <p className = "my-0">{item.hasOwnProperty('calo_reg') && item.calo_reg == 1 ? -item.components_quantity_kg.toFixed(3) : item.quantity_value_kg}</p>
            <p className = "my-0">{item.PercentTaxedReg}%</p>
            <p className = "my-0">Caus.Mov. {item.CausaleMovimento}</p>
            <p className = "my-0">Pos.Fis. {item.PosFiscale}</p>
            { item.hasOwnProperty('calo_reg') && item.calo_reg == 1 ?  <p className = "my-0"><b>Calo: {item.calo_kg_reg} ({item.calo_kg})</b></p> : null}
            </td> 
            <td>{item.CaricoNazKG}</td>    
            <td>{item.CaricoNazIC}</td>    
            <td>{item.ScaricoNazKG}</td>    
            <td>{item.ScaricoNazIC}</td>    
            <td>{item.ScaricoSICKG}</td>    
            <td>{item.CaricoCEEKG}</td>    
            <td>{item.CaricoCEEIC}</td>    
            <td>{item.ScaricoCEEKG}</td>    
            <td>{item.ScaricoCEEIC}</td>    
            <td>{item.IC && item.IC > 0 ? (<><p className = "my-0">IC: {item.IC}</p><p className = "my-0">CONOU: {item.CONOU}</p></>) : null}</td>    
            <td>
                <p className = "my-0">
                    <FontAwesomeIcon icon={additionalDataVisible ? faMinus : faPlus} onClick={switchAdditionalDataVisible} style={{cursor: 'pointer'}} />
                </p>
                {additionalDataVisible && <pre key={index}>{JSON.stringify(item, null, 2)}</pre>}
            </td>
            
        </tr></>
    );

}; 

const Olluda = () => {
    const [obj, setObj] = useState({});

    const [formData, setFormData] = useState({
        year: 2024,
        month: new Date().getMonth() + 1,
        RootCode: '',
    });

    // Handle input changes and update state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform actions with form data, such as sending it to a server
        console.log('Form submitted:', formData);
        setObj(formData); // You can also reset the form if needed
    };

    useEffect(() => {}, [formData]);

    return (
        <>
            <div>
                <h1>Registri Olluda</h1>
            </div>
            <div>
                <form onSubmit={handleSubmit} className="form-container">
                    <div className="input-container">
                        <label htmlFor="year">Anno:</label>
                        <select
                            id="year"
                            name="year"
                            value={formData.year}
                            onChange={handleInputChange}
                        >
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="month">Mese:</label>
                        <select
                            id="month"
                            name="month"
                            value={formData.month}
                            onChange={handleInputChange}
                        >
                            <option value="1">Gennaio</option>
                            <option value="2">Febbraio</option>
                            <option value="3">Marzo</option>
                            <option value="4">Aprile</option>
                            <option value="5">Maggio</option>
                            <option value="6">Giugno</option>
                            <option value="7">Luglio</option>
                            <option value="8">Agosto</option>
                            <option value="9">Settembre</option>
                            <option value="10">Ottobre</option>
                            <option value="11">Novembre</option>
                            <option value="12">Dicembre</option>
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="RootCode">Registro:</label>
                        <select
                            id="RootCode"
                            name="RootCode"
                            value={formData.RootCode}
                            onChange={handleInputChange}
                        >
                            <option value="xxx">---</option>
                            <option value="BG_MPSIC">BG_MPSIC</option>
                            <option value="BG_PFSIC">BG_PFSIC</option>
                            <option value="BG_ASSOLTA">BG_ASSOLTA</option>
                            <option value="BG_ADDITIVI">BG_ADDITIVI</option>
                            <option value="BG_%">Tutti</option>

                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
            <Content formData={formData}/>
        </>
    );
};

export default Olluda;
