import { useNavigate } from 'react-router-dom';

export const useAuthorization = () => {

    const navigate = useNavigate();

    const checkPageAuthorization = async (page) => {
    const AuthUrl = process.env.REACT_APP_AUTH_API;

    try {
      // Simulate API call for authentication
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify({ page }), // Correctly stringify the body object
      };

      const response = await fetch(AuthUrl, options);
      const data = await response.json();

      if (response.ok) {
        if (Object.keys(data).indexOf('error') >= 0) {
          navigate('/login');
          return Promise.reject('Authentication error');
        }
        return data;
      } else {
        navigate('/login');
        return Promise.reject('Authentication error');
      }
    } catch (error) {
      navigate('/login');
      return Promise.reject('An error occurred during authentication');
    }
  };

  return {
    checkPageAuthorization,
  };
};

export default useAuthorization;