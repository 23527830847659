import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import CardEnergetici from './card.js';
import { useAuthorization } from '../functions/authorization.js';

const RegistriEnergeticiPerPeriodo = () => {

    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [RootCode, setRootCode] = useState(null);
    const [cards, setCards] = useState([]);

    useEffect(() => {
//        console.log(startDate, endDate, RootCode);

        if (startDate && endDate && RootCode) {
            const generatedCards = [];
            const currentDate = new Date(startDate); // Create a separate variable to iterate over dates
            const end = new Date(endDate);
            
            while (currentDate <= end) {
                //console.log(C, RootCode, startDate.toLocaleString('sv').split(' ')[0], endDate.toLocaleString('sv').split(' ')[0]);
                generatedCards.push(
                    <CardEnergetici key = {RootCode + currentDate.toLocaleString('sv').split(' ')[0]} date={new Date(currentDate)} RootCode={RootCode} color="blue" />
                );
                currentDate.setDate(currentDate.getDate() + 1);
            }
            setCards(generatedCards);
        }
    }, [startDate, endDate, RootCode]);

    const { checkPageAuthorization } = useAuthorization();

    return (
        <>
            <div><h1>Registri energetici (per periodo)</h1></div>
            <div>
                <form>
                    <div>
                        <label>Start Date:</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(d) => {
                                setStartDate(d);
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Select start date"
                            dateFormat="dd/MM/yyyy"
                            minDate={endDate ? new Date(endDate.getTime() - (31 * 24 * 60 * 60 * 1000)) : null}
                            maxDate={endDate ? new Date(endDate.getTime() - (0 * 24 * 60 * 60 * 1000)) : null}
/>

                        <label>End Date:</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(d) => {
                                setEndDate(d);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Select end date"
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate ? new Date(startDate.getTime() + (0 * 24 * 60 * 60 * 1000)) : null}
                            maxDate={startDate ? new Date(startDate.getTime() + (31 * 24 * 60 * 60 * 1000)) : new Date()}
                        />
                    </div>
                    <div>
                        <label htmlFor="RootCode">Registro:</label>
                        <select
                            id="RootCode"
                            name="RootCode"
                            value={RootCode}
                            onChange={(event) => {
                                setRootCode(event.target.value);
                            }}
                        >
                            <option value="">-- Seleziona --</option>
                            <option value="BG_MPEN">BG_MPEN</option>
                            <option value="BG_PFEN">BG_PFEN</option>
                            <option value="MI_RAGIA">MI_RAGIA</option>
                            <option value="MI_D80">MI_D80</option>
                            <option value="MI_GUA">MI_GUA</option>
                            <option value="MI_ISOESANO">MI_ISOESANO</option>
                        </select>
                    </div>
                    {<button type="submit">Submit</button>}
                </form>
            </div>
            <div>{cards}</div>
        </>
    );
};

export default RegistriEnergeticiPerPeriodo;
