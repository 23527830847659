// energetici/card.js

import React, { useState, useEffect, useRef } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Modal } from 'bootstrap'; // Import Bootstrap components

import { BlobProvider } from '@react-pdf/renderer';
import { PDFDocument, rgb } from 'pdf-lib';

import RegistroGiornaliero from  './RegistroGiornaliero.js' 


const ConvertReactPdfToPdfLib = ({date, RootCode, elements, balance}) => {
  const [pdfDoc, setPdfDoc] = useState(null);

  const handleBlob = async (blob) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const pdfDocument = await PDFDocument.load(arrayBuffer);
      setPdfDoc(pdfDocument);
    };
    reader.readAsArrayBuffer(blob);
  };

  return (
    <BlobProvider document={<RegistroGiornaliero date = {date} RootCode = {RootCode} elements = {elements} balance = {balance}/>} documentTitle="document.pdf">
      {({ blob }) => {
        if (!pdfDoc && blob) {
          handleBlob(blob);
        }

        return (
          <span className ="mx-3" >
            {pdfDoc && (<FontAwesomeIcon icon={faFilePdf} style = {{color: "red"}} onClick={async () => {
                // Modify the PDF document using pdf-lib
                 /*
                const firstPage = pdfDoc.getPages()[0];
               
                firstPage.drawText('Text added with pdf-lib', {
                  x: 50,
                  y: firstPage.getHeight() / 2,
                  size: 30,
                  color: rgb(0, 0, 0),
                });
                */

                // Save the modified PDF as bytes
                const modifiedPdfBytes = await pdfDoc.save();

                // Open the modified PDF in a new tab
                const pdfDataUri = URL.createObjectURL(new Blob([modifiedPdfBytes], { type: 'application/pdf' }));
                window.open(pdfDataUri, '_blank');
              }} />) }

          </span>
        );
      }}
    </BlobProvider>
  );
};

const RowBalanceModal = ({data, NCGroup, date}) => {
  const modalRef = useRef(null);

  const handleShow = () => {
    const modal = new Modal(modalRef.current);
    modal.show();
  };

  const handleHide = () => {
    const modal = Modal.getInstance(modalRef.current);
    if (modal) {
      modal.hide();
    }
  };

  var rows = [];
  if (data)
    if (data.hasOwnProperty('items')) {
      for (var item_code in data.items) {
        var _link = 'https://mechfluids.com/cdg/cgen/glog/stock_inventory/stock_inventory_items_movements_recap_control_panel.php?cac='
        var link = <><Link to={_link + item_code} style={{ textDecoration: 'none', color: "blue" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} /></Link> </>;
          
        rows.push(<><tr>
          <td>{item_code} {link}</td>
          <td>{data.items[item_code].item_desc}</td>      
          <td>{data.items[item_code].kg.toFixed(3)}</td>
          <td>{data.items[item_code].timestamp}</td>
          </tr></>);
      }  
    }

  return (
    <>
      {/* Font Awesome icon that triggers the modal */}
      <FontAwesomeIcon icon={faPlus} onClick={handleShow} style={{cursor: 'pointer'}} />

      {/* Modal component */}
      <div ref={modalRef} className="modal fade">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Dettaglio gruppo {NCGroup} {date}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleHide}></button>
            </div>
            <div className="modal-body">
            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
              <thead>
                <tr className="table-light" >
                <th>Codice</th>
                <th>Descrizione</th>
                <th>Giacenza (kg)</th>
                <th>Ultimo movimento</th>
              </tr>
              </thead>
              <tbody>{rows}</tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleHide}>Close</button>
              {/*<button type="button" className="btn btn-primary" onClick={handleHide}>Save changes</button>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Row = ({index, element}) => {

    var textcolor = "black";
    if (element.quantity_kg < 0) textcolor = "red";

    return (
          <>
          <tr key = {index}>
          <td>{element.in_registro}</td>
          <td><p className = "my-0">{element.timestamp}</p><p className = "my-0">{element.movement_type_code}</p></td>
          <td><p className = "my-0">{element.protocol_id}</p><p className = "my-0">{element.protocol_type_code} {element.task_id}</p></td>
          <td><p className = "my-0">{element.TipoDoc ? element.TipoDoc : element.doc_type} {element.NumDoc ? element.NumDoc :element.doc_number}</p><p className = "my-0">{element.DataDoc ? element.DataDoc : element.doc_date}</p></td>
          <td><p className = "my-0">{element.item_code}</p><p className = "my-0">{element.item_desc}</p><p className = "my-0">{element.NC} ({element.NCGroup})</p></td>
          <td><p className = "my-0">{element.PIVA_ADM ? element.PIVA_ADM : (element.counterpart_piva ? element.counterpart_piva  : "")}</p><p className = "my-0">{element.counterpart_desc_ADM ? element.counterpart_desc_ADM :(element.counterpart_desc ? element.counterpart_desc : "")}</p></td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG1' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG2' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG3' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG4' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG5' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG6' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCG7' ? element.quantity_kg : ""}</td>
          <td className="text-center" style={{color: textcolor}} >{element.NCGroup == 'NCGA' ? element.quantity_kg : ""}</td>
          <td>{(element.protocol_type_code == 'moina' && Object.keys(element.additional_info).length > 0) ? 
            <>
            <p className="my-0">{element.additional_info.target_item_code}</p>
            <p className="my-0">{element.additional_info.target_item_desc}</p>
            <p className="my-0">{element.additional_info.target_quantity_value} {element.additional_info.target_quantity_unit_of_measure} {element.additional_info.target_quantity_unit_of_measure == 'kg' ? null : element.additional_info.target_quantity_kg +' kg'} (DiBa {element.additional_info.diba_code})</p>
            <p className="my-0">NC {element.additional_info.target_NC} ({element.additional_info.target_NCGroup})</p>
            </> 
            : null}</td>
          </tr>
          </>
        );
    
      }
    
      const RowBalance = ({date, RootCode}) => {

        var branch_code = "lallio";
        if (RootCode.startsWith("MI")) branch_code = 'cuggiono';

        const navigate = useNavigate();
        const [error, setError] = useState('');
        const [balance, setBalance] = useState({});
        const [isLoading, setIsLoading] = useState(false);      

        useEffect(() => {}, [isLoading]); // end useEffect

//        useEffect(() => {
      
          const fetchData = async () => {

          setIsLoading(true);
          const PathToBackend = process.env.REACT_APP_BACKEND_URL;
      
          const Url = PathToBackend + '/api/energetici/balance/';
      
          try {
              // Simulate API call for authentication
              var _date = date.toLocaleString('sv')
              _date = _date.split(" ")
              _date = _date[0]
      
              var request = { 
                "date": _date,
                "RootCode": RootCode,
                "branch_code": branch_code
              }
      
              //console.log(request);  

              const response = await fetch(Url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
              });

              setIsLoading(false);

              const data = await response.json();
        
              if (response.ok) {
                //console.log(data)
                if (data.auth === 0)  navigate('/login');
                setBalance(data);
      
              } else {
                navigate('/login');
      
                  // Authentication failed, handle error
                setError(data.message || 'Authentication failed');
              }
            } catch (error) {
              // Handle network or other errors
              setError('An error occurred during authentication');
            }
            
      };
  /*    
        fetchData();
      
            // Cleanup function (optional)
          // If you need to clean up after the effect, return a function from useEffect
          return () => {
            // Code inside this cleanup function will run when the component is unmounted
            // This can be used for canceling subscriptions, clearing intervals, etc.
          };
        }, [date]); // end useEffect
  */          
        if (!(balance.hasOwnProperty('group_balances'))) return (<>
            <>
              <tr className="table-light  text-center" >
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th colSpan={8}><button onClick={fetchData} disabled={isLoading}>{isLoading ? 'Loading...' : 'Carica Saldi MF'}</button></th>
                <th></th>
              </tr>
            </>  
        </>);


        else {

        var balances = balance.group_balances;

        return (
            <>
              <tr className="table-light  text-center" >
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{(balances.hasOwnProperty('NCG1')) ? balances.NCG1.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG1')) ? balances.NCG1 : null} NCGroup = "NCG1" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG2')) ? balances.NCG2.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG2')) ? balances.NCG2 : null} NCGroup = "NCG2" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG3')) ? balances.NCG3.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG3')) ? balances.NCG3 : null} NCGroup = "NCG3" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG4')) ? balances.NCG4.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG4')) ? balances.NCG4 : null} NCGroup = "NCG4" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG5')) ? balances.NCG5.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG5')) ? balances.NCG5 : null} NCGroup = "NCG5" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG6')) ? balances.NCG6.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG6')) ? balances.NCG6 : null} NCGroup = "NCG6" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCG7')) ? balances.NCG7.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCG7')) ? balances.NCG7 : null} NCGroup = "NCG7" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th>{(balances.hasOwnProperty('NCGA')) ? balances.NCGA.kg.toFixed(3) : null}  <RowBalanceModal data = {(balances.hasOwnProperty('NCGA')) ? balances.NCGA : null} NCGroup = "NCGA" date = {date.toLocaleString('sv').split(' ')[0]} /></th>
                <th></th>
              </tr>
            </>  
            );
          }

      };
      








const CardEnergetici = ({date, RootCode, color}) => {

  const navigate = useNavigate();
  const [error, setError] = useState('');

  var rows = [];
  const [elements, setElements] = useState({});
  const [balance, setBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {

    
    setIsLoading(true);

    const PathToBackend = process.env.REACT_APP_BACKEND_URL;

    const Url = PathToBackend + '/api/energetici/movimenti/';

    console.log(Url)

    try {
        // Simulate API call for authentication
        var _date = date.toLocaleString('sv')
        _date = _date.split(" ")
        _date = _date[0]
        var year = _date.split("-")
        year = year[0]

        var request = { 
          "date": _date,
          "year": year,
          "RootCode": RootCode,
        }

        const response = await fetch(Url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        });
  
        const data = await response.json();
        console.log(343, data)
        if (response.ok) {
          console.log(data)
          if (data.auth === 0)  navigate('/login');
          console.log(data["data"])
          setElements(data["data"]);
          setBalance(data["balance"]);
          //console.log(data["balance"])
          setIsLoading(false);
        } else {
          navigate('/login');

            // Authentication failed, handle error
          setError(data.message || 'Authentication failed');
        }
      } catch (error) {
        // Handle network or other errors
        setError('An error occurred during authentication');
      }
      
};


useEffect(() => {}, [elements]);
useEffect(() => {}, [balance]);
useEffect(() => {}, [isLoading]);

useEffect(() => {
    setElements([]);
    setBalance([]);
    fetchData();
    // Cleanup function (optional) // If you need to clean up after the effect, return a function from useEffect
    return () => { 
      // Code inside this cleanup function will run when the component is unmounted // This can be used for canceling subscriptions, clearing intervals, etc.
       };
  }, [date]); // end useEffect

  var visible = false;
  //  console.log(elements)
  for (var e in elements) {
    visible = true;
    
    rows.push(<Row index = {e} element =  {elements[e]}/>);
  }
  
  if (isLoading) return (<>
        <div className={`container-fluid p-1 mx-3 mt-2 ${true ? 'd-all' : 'd-none'}`} style={{ width: '98%', backgroundColor: color }}>
        <div className="card m-0 p-0" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">{date.toLocaleString('sv').split(' ')[0]} {RootCode} </h5>
              <div>Loading ... </div>
          </div>
        </div>
      </div>
  
  </>);
  else 
  return (  
      <>
      <div className={`container-fluid p-1 mx-3 mt-2 ${visible ? 'd-all' : 'd-none'}`} style={{ width: '98%', backgroundColor: color }}>
        <div className="card m-0 p-0" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">{date.toLocaleString('sv').split(' ')[0]} {RootCode}
            <ConvertReactPdfToPdfLib date={date} RootCode = {RootCode} elements = {elements} balance = {balance}/>
            </h5>
              <div>
              <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
              <thead>
                <tr className="table-light" >
                <th>R</th>
                <th>DataMov MF</th>
                <th>Protocollo/ Task</th>
                <th>Documento</th>
                <th>Articolo</th>
                <th>Controparte</th>
                <th>NCG1</th>
                <th>NCG2</th>
                <th>NCG3</th>
                <th>NCG4</th>
                <th>NCG5</th>
                <th>NCG6</th>
                <th>NCG7</th>
                <th>NCGA</th>
                <th>Prodotto finito</th>
              </tr>
              <RowBalance date = {date} RootCode = {RootCode}/>
              <tr className="text-center" style ={{backgroundColor: "red"}}>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG1) == 'undefined' ? "" : balance.NCG1.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG2) == 'undefined' ? "" : balance.NCG2.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG3) == 'undefined' ? "" : balance.NCG3.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG4) == 'undefined' ? "" : balance.NCG4.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG5) == 'undefined' ? "" : balance.NCG5.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG6) == 'undefined' ? "" : balance.NCG6.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG7) == 'undefined' ? "" : balance.NCG7.start}</th>
                <th style ={{backgroundColor: "#00ffff", color: "black"}}>{typeof(balance.NCG0) == 'undefined' ? "" : balance.NCG0.start}</th>
                <th></th> 
              </tr>      
              </thead>
              <tbody>{rows}</tbody>

              <thead>

              <tr className="text-center" >
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG1) == 'undefined' ? "" : balance.NCG1.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG2) == 'undefined' ? "" : balance.NCG2.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG3) == 'undefined' ? "" : balance.NCG3.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG4) == 'undefined' ? "" : balance.NCG4.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG5) == 'undefined' ? "" : balance.NCG5.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG6) == 'undefined' ? "" : balance.NCG6.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG7) == 'undefined' ? "" : balance.NCG7.end}</th>
                <th style ={{backgroundColor: "blue", color: "white"}}>{typeof(balance.NCG0) == 'undefined' ? "" : balance.NCG0.end}</th>
                <th></th>
              </tr>      

              </thead>

              </table>
              </div>
          </div>
        </div>
      </div>
  </>

      );
};

export default CardEnergetici;