import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {getToken} from '../utils/common.js'

const VIAUpdater = ({type, element, fieldName, desc, currentValue}) =>  {

  //console.log(type, element, fieldName, desc, currentValue);

  const [parameterValue, setParameterValue] = useState(currentValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [checkboxState, setCheckboxState] = useState(parameterValue !== null);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
    console.log(event.target.value);

  };

  const handleCheckboxChange = (event) => {
    //console.log(event.target.checked);
    setCheckboxState(!checkboxState);
  };

  useEffect(() => {
    setInitialValue(parameterValue);
  }, []);

  useEffect(() => {
  }, [checkboxState, disabled]);


  var o = <></>;
  if (type == 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
  else if (type == 'number') o = <input type="number"  step = "0.001" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
  else if (type === 'date') o =  <DatePicker
      selected={new Date(parameterValue)}
      onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
    dateFormat="dd/MM/yyyy"
    />;
  else if (type == 'bool') o = (<select
        value={parameterValue}
        onChange={handleChange}>
        <option value="">--</option>
        <option value="1">SI</option>
        <option value="0">NO</option>
        </select>);



  const updateParameter = async () => {
    var body =  { 
        update_key: process.env.REACT_APP_UPDATE_KEY,
        company: 'NOWAL',
        protocol_id: element.protocol_id,
        fieldName: fieldName, 
        override: checkboxState,
        value: parameterValue
    };
    console.log(60);
    console.log(body);

    try {
        setDisabled(true);
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/movements/VIAupdate/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }
      setInitialValue(parameterValue);
      setDisabled(false);
      console.log(response, data);
//      console.log('Parameter updated successfully');
    } catch (error) {
        console.log(error.message);
      setErrorMessage('Error updating parameter: ' + error.message);
    }
  };

  return (<>
    {/*<div className="d-flex align-items-center">*/}
      <input 
        type="checkbox" 
        checked={checkboxState} // Update the checked attribute
        onChange={handleCheckboxChange} 
        className="mx-1" 
      />
      <span>{desc}</span>
      {checkboxState && o}
      {((parameterValue !== initialValue) || checkboxState === (parameterValue == null)) && (
        <button onClick={updateParameter} className="btn btn-primary mx-1">
          Update
        </button>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    {/*</div>*/}
    </>
  );

}

export default VIAUpdater;
