import './App.css';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route, NavLink, Navigate, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {getToken, setUserSession, removeUserSession} from './utils/common.js'

import PublicRoutes from './utils/PublicRoutes.js'
import PrivateRoutes from './utils/PrivateRoutes.js'

import RegistriEnergetici from './EnergeticiPage';
import RegistriEnergeticiPerPeriodo from './energetici/EnergeticiPerPeriodo';

//import LoginPage from './LoginPage.js'
import Navbar from './pages/Navbar.js'
import Sidebar from './pages/Sidebar.js'
import Login from './pages/Login.js'
import Dashboard from './pages/Dashboard.js'

import Movements from './movimenti/Movements.js';
import Lavorazioni from './movimenti/Lavorazioni.js';
import AccessControl from './users/UsersAccess.js';
import UsersMain from './users/UsersMain.js'
import Olluda from './olluda/Olluda.js'

const App = () => {

//  const history = useNavigate();
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  var m = params.get('m');
  if (m == 1) m = 1;
  else m = 0;
  
  const [sidebarVisible, setSidebarVisible] = useState(m);
  const [authLoading, setAuthLoading] = useState(true);

  const toggleSidebar = () => {
    if (sidebarVisible == 0) setSidebarVisible(1);
    else setSidebarVisible(0);
  };

  useEffect(() => {

    const token = getToken();
   // console.log(sessionStorage);
    //console.log("TOKEN", getToken());
    if (!token) {
      return;
    }

    const API = process.env.REACT_APP_BACKEND_URL;   
    
    axios.get(`${API}/auth/verify/${token}`).then(response => {
//      console.log("VERIFY", response);

      if (!response.hasOwnProperty("data") || !response.data.hasOwnProperty("status") || !response.data.status === "OK")
      {
        removeUserSession();
        window.location.href = '/login';
      } 
      setAuthLoading(false);
    }).catch(error => {
 //     console.log("ERROR", error);
      removeUserSession();
      window.location.href = '/login';
    setAuthLoading(false);
    });

    setAuthLoading(false);

  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>
  }


  return (
    <Router>
      <div>
        <Navbar sidebarVisible={sidebarVisible} toggleSidebar={toggleSidebar} />
      </div>
      <div>
        <div className={`container-fluid mt-4`}>
          <div className="row">
            <Sidebar sidebarVisible={sidebarVisible}/>
            <div className={` ${sidebarVisible ? 'col-md-10' : 'col-md-12'}`}>
              <div className="container-fluid">
                <Routes>
                  <Route element={<PublicRoutes />}>
                    <Route path="/login" element={<Login />} />
                  </Route>
                  <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/users/*" element={<UsersMain />} />
                    <Route path="/energetici" element={<RegistriEnergetici />} />
                    <Route path="/lavorazioni" element={<Lavorazioni />} />  
                    <Route path="/energetici/registri_per_periodo" element={<RegistriEnergeticiPerPeriodo />} />
                    <Route path="/movimenti" element={<Movements />} />
                    <Route path="/olluda" element={<Olluda />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
