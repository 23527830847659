import React, { useState, useRef, useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import RobotoBold from '../fonts/Roboto/Roboto-Bold.ttf';
import RobotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';

Font.register({ family: 'Roboto', fonts: [{ src: RobotoRegular, fontWeight: 'normal' }, { src: RobotoBold, fontWeight: 'bold' }] });

const styles = StyleSheet.create({
  page: {
//    flexDirection: 'row',
    backgroundColor: 'white',
    fontFamily: 'Roboto',
  },
  section1: {
//    position: 'absolute',
//    top: 100,
//    left: 50,
    margin: '50 0 0 0 ',
    padding: 20,
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    position: 'relative', // Make rows relatively positioned
  },
  section2: {
//    position: 'relative',
//    top: 150,
//    left: 50,
    margin: '0',
    padding: '0 20 20 20',
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },

});


const CellElement = ({ width, textAlign, fontWeight, fontSize, border, text }) => {
  const s = {
    width: width,
    textAlign: textAlign,
    fontWeight: fontWeight,
    fontSize: fontSize,
    padding: 1,
    borderTopWidth: border.indexOf('T') >= 0 ? 1 : 0,
    borderBottomWidth: border.indexOf('B') >= 0 ? 1 : 0,
    borderLeftWidth: border.indexOf('L') >= 0 ? 1 : 0,
    borderRightWidth: border.indexOf('R') >= 0 ? 1 : 0,
  };

  return (
    <View style={s}>
      <Text>{text}</Text>
    </View>
  );
};

const RegistroMensileOlluda = ({ tableRows }) => {

    const [pages, setPages] = useState([]);
  
    useEffect(() => {
      const calculatedPages = calculatePages(tableRows);
      setPages(calculatedPages);
    }, [tableRows]);
  
    const calculatePages = (rows) => {
      const maxRowsPerPage = 45; // You can adjust this number based on your content
      const pages = [];
      let currentPage = [];
      let rowCount = 0;
  
      rows.forEach((row) => {
        const estimatedRowHeight = 1; // Adjust as needed
  
        if (rowCount + estimatedRowHeight > maxRowsPerPage) {
          // Start a new page
          pages.push(currentPage);
          currentPage = [];
          rowCount = 0;
        }
  
        currentPage.push(row);
        rowCount += estimatedRowHeight;
      });
  
      // Add the remaining rows to the last page
      if (currentPage.length > 0) {
        pages.push(currentPage);
      }
  
      return pages;
    };
  
    return (
      <Document>
        {pages.map((page, index) => (
          <Page key={index} size="A4" orientation="landscape" style={styles.page}>
            <View style={styles.section1}>
              {page.map((row, rowIndex) => (
                <Section1Row key={rowIndex} row={row} />
              ))}
            </View>
          </Page>
        ))}
      </Document>
    );
  };


const Section1 = ({ tableRows }) => {


    return (
  
          <View style={styles.section1}>
                {tableRows.map((row) => <Section1Row row = {row}/>)}
          </View>
    );
  
  
  };

const Section1Row = ({row}) => {

    function format_date(date) {
        if (!date) return null;
        try{
        var d = date.split("-")
        return d[2] +'/' + d[1] +'/'+d[0]
        } catch (error) {return null}
    };

    function format_number(number) {
        var n = number && String(number.toFixed(3)).replace(".", ",")
        return n
    }

    function format_counterpart(row) {
        var o = ''
        o += (row.hasOwnProperty('p_iva_reg') && row.p_iva_reg ? row.p_iva_reg : '')
        o += ' '
        o += (row.hasOwnProperty('counterpart_desc_reg') && row.counterpart_desc_reg ? row.counterpart_desc_reg : '')
        return o
    }


return  (<>
    <View style={styles.tableRow}>
    {CellElement({ width: '2.5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: row.NumIntDoc })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_date(row.DataMov) })}
    {CellElement({ width: '2.5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: row.TipoDocReg })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: row.NumDocReg })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_date(row.DataDocReg) })}
    {CellElement({ width: '25%', textAlign: 'left', fontWeight: '', fontSize: 6, border: 'BL', text: format_counterpart(row) })}
    {CellElement({ width: '10%', textAlign: 'left', fontWeight: '', fontSize: 6, border: 'BL', text: row.item_code })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.CaricoNazKG)})}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.CaricoNazIC)})}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.ScaricoNazKG) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.ScaricoNazIC) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.ScaricoSICKG) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.CaricoCEEKG) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.CaricoCEEIC) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BL', text: format_number(row.ScaricoCEEKG) })}
    {CellElement({ width: '5%', textAlign: 'center', fontWeight: '', fontSize: 6, border: 'BLR', text: format_number(row.ScaricoCEEIC) })}
    </View>
    </>);
};

export default RegistroMensileOlluda;
