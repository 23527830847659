import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate, Link } from 'react-router-dom';

const Sidebar = ({sidebarVisible}) => {

    return (
      <>
    
        <div className={`col-md-2 ${sidebarVisible ? 'd-all' : 'd-none'}`}>
            <div className="container border">
              <h4>Menu</h4>
              <nav class="navbar bg-white navbar-light">
                <div class="container-fluid">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="btn btn-primary my-1" href={`/energetici?m=${sidebarVisible}`}>Registri Energetici (per giorno)</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-primary my-1" href={`/energetici/registri_per_periodo/?m=${sidebarVisible}`}>Registri Energetici (per periodo)</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-primary my-1" href={`/login?m=${sidebarVisible}`}>Login</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-primary my-1" href={`/movements?m=${sidebarVisible}`}>Movimenti</a>
                    </li>
                    <li class="nav-item">
                      <a class="btn btn-primary my-1" href={`/movements/lavorazioni?m=${sidebarVisible}`}>Lavorazioni</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>  
      </>
    );
    
    };
    
export default Sidebar;    