import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, setUserSession } from '../utils/common';
import axios from 'axios';
import { MD5 } from 'crypto-js';

const Login = props => {

  const history = useNavigate();
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
//  const backendurl = process.env.REACT_APP_API_URL; 

  // handle button click of login form
  const handleLogin = () => {

    setError(null);
    setLoading(true);

//    setLoading(false);

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/login/`, 
      { username: username.value, password: MD5(password.value).toString() }
    ).then(response => {
        setLoading(false);
    //    console.log(response);
        setUserSession(response.data.key, response.data); // username changed from response.data.user
//        console.log(sessionStorage);
    window.location.reload();    
//    history('/dashboard');
    }).catch(error => {
      
      setLoading(false);
      console.log(error);
//      if (error.response.status === 400) setError(error.response.data.error.message);
//      else if (error.response.status === 500) setError(error.response.data.error.message);
//      else setError("Something went wrong. Please try again later.");
    });
  
    }

  return (
    <div>
      Login<br /><br />
      <div>
        Username<br />
        <input type="text" {...username} autoComplete="new-password" />
      </div>
      <div style={{ marginTop: 10 }}>
        Password<br />
        <input type="password" {...password} autoComplete="new-password" />
      </div>
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br />
    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;