import React, { useState, useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import RobotoBold from '../fonts/Roboto/Roboto-Bold.ttf';
import RobotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';
import nowalGreyImage from '../img/NOWAL_grey.jpg';
//import { add } from 'date-fns';

Font.register({ family: 'Roboto', fonts: [{ src: RobotoRegular, fontWeight: 'normal' }, { src: RobotoBold, fontWeight: 'bold' }] });

const styles = StyleSheet.create({
  page: {
//    flexDirection: 'row',
    backgroundColor: 'white',
    fontFamily: 'Roboto',
  },
  section1: {
//    position: 'absolute',
//    top: 100,
//    left: 50,
    margin: '100 0 0 0 ',
    padding: 20,
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    position: 'relative', // Make rows relatively positioned
  },
  section2: {
//    position: 'relative',
//    top: 150,
//    left: 50,
    margin: '0',
    padding: '0 20 20 20',
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
  },

});

const imageToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const CellElement = ({ width, textAlign, fontWeight, fontSize, border, text }) => {
  const s = {
    width: width,
    textAlign: textAlign,
    fontWeight: fontWeight,
    fontSize: fontSize,
    padding: 1,
    borderTopWidth: border.indexOf('T') >= 0 ? 1 : 0,
    borderBottomWidth: border.indexOf('B') >= 0 ? 1 : 0,
    borderLeftWidth: border.indexOf('L') >= 0 ? 1 : 0,
    borderRightWidth: border.indexOf('R') >= 0 ? 1 : 0,
  };

  return (
    <View style={s}>
      <Text>{text}</Text>
    </View>
  );
};

const VIA = ({ baseElement, additionalData }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      const response = await fetch(nowalGreyImage);
      const blob = await response.blob();
      const base64Image = await imageToBase64(blob);
      setImageData(base64Image);
    };

    loadImage();
  }, []);

  return (
    <Document>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Section1 baseElement={baseElement} additionalData={additionalData}/>
          <Section2 baseElement={baseElement} additionalData={additionalData}/>
          <Section3 baseElement={baseElement} additionalData={additionalData}/>
        </View>
      </Page>
    </Document>
  );
};

const Section1 = ({ baseElement, additionalData }) => {

  var Q = additionalData.target_quantity_kg.toFixed(3).replace(".",",") + ' kg';
  if (additionalData.VIA.calo_in_registro == "1") {
    Q = Q + ' (netto) / ' + (-additionalData.components_quantity_kg).toFixed(3).replace(".", ",")+' kg (lordo)'
  }

  return (
          <View style={styles.section1}>
            <View style={styles.tableRow}>)
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Ordine di produzione' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: baseElement.protocol_id })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Articolo' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: additionalData.target_item_desc })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Codice' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: additionalData.target_item_code })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Quantità prodotta' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: Q })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Numero VIA' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: baseElement.VIA.VIA_number })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Data VIA (ISO)'})}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: baseElement.VIA.VIA_date })}
            </View>
            <View style={styles.tableRow}>
              {CellElement({ width: '25%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: '', text: 'Codice DIBA' })}
              {CellElement({ width: '75%', textAlign: 'left', fontWeight: '', fontSize: 12, border: '', text: additionalData.diba_code })}
            </View>
          </View>
  );


};

const Section2 = ({ baseElement, additionalData }) => {
  
  var VIA_info = additionalData.VIA;
  var kg_total = 0;
  var ic_total = 0;

  var MP = <></>
  if (VIA_info.hasOwnProperty('BG_MPSIC')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_MPSIC) {
      kg -= VIA_info.BG_MPSIC[NC]

    }
    kg_total += kg;
    ic_total += parseFloat((kg * 0.78781).toFixed(2))

    var MP = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Materie prime lubrificanti' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '100%' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '0,78781 €/kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: (kg * 0.78781).toFixed(2).replace(".", ",")+' €' })}

    {/*CellElement({ width: '54%', textAlign: 'left', fontWeight: '', fontSize: 12, border: 'LRTB', text: JSON.stringify(VIA_info.BG_MPSIC, null, 2) })*/}
    </View>
    </>

  }

  var Additivi = <></>
  if (VIA_info.hasOwnProperty('BG_ADDITIVI')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_ADDITIVI) {
      kg -= VIA_info.BG_ADDITIVI[NC]
    }

    kg_total += kg;
    ic_total += parseFloat((kg * 0.78781).toFixed(2))

    var Additivi = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Additivi' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '100%' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '0,78781 €/kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: (kg * 0.78781).toFixed(2).replace(".", ",")+' €' })}

    {/*CellElement({ width: '54%', textAlign: 'left', fontWeight: '', fontSize: 12, border: 'LRTB', text: JSON.stringify(VIA_info.BG_MPSIC, null, 2) })*/}
    </View>
    </>

  }

  var PF = <></>
  if (VIA_info.hasOwnProperty('BG_PFSIC')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_PFSIC) {
      kg -= VIA_info.BG_PFSIC[NC]
    }

    kg_total += kg;
    ic_total += parseFloat((kg * 0.78781).toFixed(2))

    var PF = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Altri prodotti chimici tassati' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '100%' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '0,78781 €/kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: (kg * 0.78781).toFixed(2).replace(".", ",")+' €' })}

    {/*CellElement({ width: '54%', textAlign: 'left', fontWeight: '', fontSize: 12, border: 'LRTB', text: JSON.stringify(VIA_info.BG_MPSIC, null, 2) })*/}
    </View>
    </>

  }

  var Esenti = <></>

  if (VIA_info.hasOwnProperty('null') || VIA_info.hasOwnProperty('')) {
    
    var kg = 0

    if (VIA_info.hasOwnProperty('null')) {
    for (var NC in VIA_info.null) {
      kg -= VIA_info.null[NC]
    }
    }

    if (VIA_info.hasOwnProperty('')) {
      for (var NC in VIA_info['']) {
        kg -= VIA_info[''][NC]
      }
      }
  

    kg_total += kg;

    var Esenti = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Altri prodotti non tassati' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}

    {/*CellElement({ width: '54%', textAlign: 'left', fontWeight: '', fontSize: 12, border: 'LRTB', text: JSON.stringify(VIA_info.BG_MPSIC, null, 2) })*/}

    </View>
    </>

  }


  
  return (
    <View style={styles.section2}>
      <View style={styles.tableRow}>
        {CellElement({ width: '100%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: 'LRTB', text: 'LAVORAZIONE' })}
      </View>
      <View style={styles.tableRow}>
        {CellElement({ width: '32%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Componente' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Quantità' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: '% Tassato' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Aliquota' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Imposta' })}
        </View>
        {MP}{Additivi}{PF}{Esenti}
        <View style={styles.tableRow}>
        {CellElement({ width: '32%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Totale' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: kg_total.toFixed(3).replace(".",",") + ' kg' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: '' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: '' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: ic_total.toFixed(2).replace(".", ",")+' €' })}
        </View>
    {/*<View style={styles.tableRow}>
      {CellElement({ width: '100%', textAlign: 'left', fontSize: 8, border: '', text: JSON.stringify(baseElement, null, 2) })}
    </View>
    <View style={styles.tableRow}>
      {CellElement({ width: '100%', textAlign: 'left', fontSize: 8, border: '', text: JSON.stringify(additionalData, null, 2) })}
  </View>*/}
  </View>

  );


};


const Section3 = ({ baseElement, additionalData }) => {
  
  var VIA_info = additionalData.VIA;
  var kg_total = 0;

  const Q_calo_ADM = VIA_info.Q_calo_ADM;
  const Q_calo_in_registro = VIA_info.Q_calo_in_registro;
  const Q_calo_percent_taxed = VIA_info.Q_calo_percent_taxed;
  const Q_calo = additionalData.balance_quantity_kg ? -additionalData.balance_quantity_kg : null;

  const calo = Q_calo_ADM ? Q_calo_ADM : Q_calo

  var MP = <></>
  if (VIA_info.hasOwnProperty('BG_MPSIC')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_MPSIC) {
      kg -= VIA_info.BG_MPSIC[NC]
    }
    kg_total += kg;

    var MP = <>
    <View style={styles.tableRow}>

    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Materie prime lubrificanti' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    </View>

      </>

  }

  var Additivi = <></>
  if (VIA_info.hasOwnProperty('BG_ADDITIVI')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_ADDITIVI) {
      kg -= VIA_info.BG_ADDITIVI[NC]
    }
    kg_total += kg;

  var Additivi = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Additivi' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    </View>
    </>

  }

  var PF = <></>
  if (VIA_info.hasOwnProperty('BG_PFSIC')) {
    
    var kg = 0
    for (var NC in VIA_info.BG_PFSIC) {
      kg -= VIA_info.BG_PFSIC[NC]
    }
    kg_total += kg;
  
    var PF = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Altri prodotti chimici tassati' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    </View>
    </>

  }

  var Esenti = <></>

  if (VIA_info.hasOwnProperty('null') || VIA_info.hasOwnProperty('')) {
    
    var kg = 0

    if (VIA_info.hasOwnProperty('null')) 
    for (var NC in VIA_info.null) {
      kg -= VIA_info.null[NC]
    }

    if (VIA_info.hasOwnProperty('')) 
      for (var NC in VIA_info['']) {
        kg -= VIA_info[''][NC]
      }
  
    kg_total += kg;
  
  var Esenti = <>
    <View style={styles.tableRow}>
    {CellElement({ width: '32%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: 'Altri prodotti chimici non tassati' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: kg.toFixed(3).replace(".", ",")+' kg' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    {CellElement({ width: '17%', textAlign: 'left', fontWeight: '', fontSize: 10, border: 'LRTB', text: '' })}
    </View>
    </>

  }

  const calo_percent = calo && kg_total > 0 ? (100 *(calo / kg_total)).toFixed(2) : null

  
  return (
    <View style={styles.section2}>
      <View style={styles.tableRow}>
        {CellElement({ width: '83%', textAlign: 'left', fontWeight: 'bold', fontSize: 12, border: 'LRTB', text: 'CALI TECNICI' })}
      </View>
      <View style={styles.tableRow}>
        {CellElement({ width: '32%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Componente' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'kg lavorati' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'kg calo' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: '% calo' })}
        </View>
        {MP}{Additivi}{PF}{Esenti}
        <View style={styles.tableRow}>
        {CellElement({ width: '32%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: 'Totale' })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: kg_total ? kg_total.toFixed(3).replace(".",",") + ' kg' : null })}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: calo ? parseFloat(calo).toFixed(3).replace(".",",")+' kg' : null})}
        {CellElement({ width: '17%', textAlign: 'left', fontWeight: 'bold', fontSize: 10, border: 'LRTB', text: calo_percent ? calo_percent.replace(".", ",") + ' %' :null })}
        </View>
    {/*<View style={styles.tableRow}>
      {CellElement({ width: '100%', textAlign: 'left', fontSize: 8, border: '', text: JSON.stringify(baseElement, null, 2) })}
    </View>
    <View style={styles.tableRow}>
      {CellElement({ width: '100%', textAlign: 'left', fontSize: 8, border: '', text: JSON.stringify(additionalData, null, 2) })}
  </View>*/}
  </View>

  );


};



export default VIA;
