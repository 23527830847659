// HomePage.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate, Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilePdf, faPlus, faMinus, faCaretRight, faFilter } from '@fortawesome/free-solid-svg-icons';

import { useAuthorization } from '../functions/authorization.js';

import TextFieldUpdater from '../updaters/textfield.js';
import axios from 'axios';

import {getToken} from '../utils/common.js'

const InputComponent = ({initialValue, setExternalValue}) => {
  // State to hold the value of the input
  const [inputValue, setInputValue] = useState(initialValue);

  // Function to handle input change
  const handleChange = (event) => {
    setInputValue(event.target.value);
    setExternalValue(event.target.value);
  };

  return (
    <div>
      {/* Input component */}
      <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>
      <input
        type="text"
        value={inputValue} // Bind input value to state
        onChange={handleChange} // Call handleChange function on change
      />
      {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
    </div>
  );
};

const CheckBoxComponent = ({tag, initialValue, setExternalValue}) => {
  // State to hold the value of the input
  const [inputValue, setInputValue] = useState(initialValue);

  // Function to handle input change
  const handleChange = (event) => {
    setInputValue(event.target.checked);
    console.log(inputValue);
    setExternalValue(!inputValue);
  };

  return (
    <span className='mx-2'>
      {/* Input component 
      <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>*/}
      <input
        type="checkbox"
        checked={inputValue} // Bind input value to state
        onChange={handleChange} // Call handleChange function on change
      />
      <label className = 'mx-1 '><b>{tag}</b></label>
      {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
    </span>
  );
};

const CardMovimenti = ({formData}) => {

  const navigate = useNavigate();
  const [elements, setElements] = useState({});
  const [rows, setRows] = useState([]);

  const [filterMov, setFilterMov] = useState('');
  const [filterArt, setFilterArt] = useState('');
  const [filterProt, setFilterProt] = useState('');
  const [filterErr, setFilterErr] = useState(false);
  const [filterReg, setFilterReg] = useState(false);
  const [filterTBV, setFilterTBV] = useState(false);

  const [queue1, setQueue1] = useState([]);
  const [queue2, setQueue2] = useState([]);
  const [queue3, setQueue3] = useState([]);
  const [queue4, setQueue4] = useState([]);
  const [queue5, setQueue5] = useState([]);
  const [queue6, setQueue6] = useState([]);
  const [queue7, setQueue7] = useState([]);
  const [queue8, setQueue8] = useState([]);


  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const [processing3, setProcessing3] = useState(false);
  const [processing4, setProcessing4] = useState(false);
  const [processing5, setProcessing5] = useState(false);
  const [processing6, setProcessing6] = useState(false);
  const [processing7, setProcessing7] = useState(false);
  const [processing8, setProcessing8] = useState(false);

  useEffect(() => {
    // Process Queue 1
    const processQueue1 = async () => {
      if (queue1.length > 0 && !processing1) {
        setProcessing1(true);
        try {
          const task = queue1[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 1:', error);
        } finally {
          setQueue1(prevQueue => prevQueue.slice(1));
          setProcessing1(false);
        }
      }
    };

    processQueue1();

  }, [queue1, processing1]);

  useEffect(() => {
    // Process Queue 2
    const processQueue2 = async () => {
      if (queue2.length > 0 && !processing2) {
        setProcessing2(true);
        try {
          const task = queue2[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 2:', error);
        } finally {
          setQueue2(prevQueue => prevQueue.slice(1));
          setProcessing2(false);
        }
      }
    };

    processQueue2();

  }, [queue2, processing2]);

  useEffect(() => {
    // Process Queue 3
    const processQueue3 = async () => {
      if (queue3.length > 0 && !processing3) {
        setProcessing3(true);
        try {
          const task = queue3[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 3:', error);
        } finally {
          setQueue3(prevQueue => prevQueue.slice(1));
          setProcessing3(false);
        }
      }
    };

    processQueue3();

  }, [queue3, processing3]);

  useEffect(() => {
    // Process Queue 4
    const processQueue4 = async () => {
      if (queue4.length > 0 && !processing4) {
        setProcessing4(true);
        try {
          const task = queue4[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 4:', error);
        } finally {
          setQueue4(prevQueue => prevQueue.slice(1));
          setProcessing4(false);
        }
      }
    };

    processQueue4();

  }, [queue4, processing4]);

  useEffect(() => {
    // Process Queue 5
    const processQueue5 = async () => {
      if (queue5.length > 0 && !processing5) {
        setProcessing5(true);
        try {
          const task = queue5[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 5:', error);
        } finally {
          setQueue5(prevQueue => prevQueue.slice(1));
          setProcessing5(false);
        }
      }
    };

    processQueue5();

  }, [queue5, processing5]);

  useEffect(() => {
    // Process Queue 6
    const processQueue6 = async () => {
      if (queue6.length > 0 && !processing6) {
        setProcessing6(true);
        try {
          const task = queue6[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 6:', error);
        } finally {
          setQueue6(prevQueue => prevQueue.slice(1));
          setProcessing6(false);
        }
      }
    };

    processQueue6();

  }, [queue6, processing6]);

  useEffect(() => {
    // Process Queue 7
    const processQueue7 = async () => {
      if (queue7.length > 0 && !processing7) {
        setProcessing7(true);
        try {
          const task = queue7[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 7:', error);
        } finally {
          setQueue7(prevQueue => prevQueue.slice(1));
          setProcessing7(false);
        }
      }
    };

    processQueue7();

  }, [queue7, processing7]);

  useEffect(() => {
    // Process Queue 8
    const processQueue8 = async () => {
      if (queue8.length > 0 && !processing8) {
        setProcessing8(true);
        try {
          const task = queue8[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 8:', error);
        } finally {
          setQueue8(prevQueue => prevQueue.slice(1));
          setProcessing8(false);
        }
      }
    };

    processQueue8();

  }, [queue8, processing8]);

  const addToQueue1 = async (task) => { setQueue1(prevQueue => [...prevQueue, task]); };
  const addToQueue2 = async (task) => { setQueue2(prevQueue => [...prevQueue, task]); };
  const addToQueue3 = async (task) => { setQueue3(prevQueue => [...prevQueue, task]); };
  const addToQueue4 = async (task) => { setQueue4(prevQueue => [...prevQueue, task]); };
  const addToQueue5 = async (task) => { setQueue5(prevQueue => [...prevQueue, task]); };
  const addToQueue6 = async (task) => { setQueue6(prevQueue => [...prevQueue, task]); };
  const addToQueue7 = async (task) => { setQueue7(prevQueue => [...prevQueue, task]); };
  const addToQueue8 = async (task) => { setQueue8(prevQueue => [...prevQueue, task]); };


  const fetchData = async () => {

      try {

        const API = process.env.REACT_APP_BACKEND_URL;
        var request = formData;
        if (request == {}) return;

        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken(), 
            },
          }

          axios.post(`${API}/api/movements/`, request, options).then(
            response => {
//                setLoading(false);
//                console.log(response);
 //               console.log("DATA", response.data.data);
                setElements(response.data.data);
            }
        ).catch(
            error => {
//                setLoading(false);
                console.log(error);
            }
        );

  
      } catch (error) {console.log('Error fetching data: '+ error)
      } finally {
        //setLoading(false);
      }
  

  };

  useEffect(() => {

    setElements({});
    setRows([]);
    setQueue1([]);
    setQueue2([]);
    setQueue3([]);
    setQueue4([]);
    setQueue5([]);
    setQueue6([]);
    setQueue7([]);
    setQueue8([]);

    fetchData();
  }, [formData]);

  useEffect(() => {

    var _rows = [];
    
    for (var e in elements) {
//      console.log(e, elements[e]);
    

    if (e % 8 == 0)       _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue1} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);    
    else if (e % 8 == 1)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue2} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 2)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue3} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 3)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue4} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 4)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue5} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 5)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue6} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 6)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue7} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);
    else if (e % 8 == 7)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue8} filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>); 
    else   
    _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue1}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);

    }

    setRows(_rows);
  
  }, [elements, filterMov, filterArt, filterProt, filterErr, filterReg, filterTBV]);


  return (
    <div className={`container-fluid p-1 mx-3 mt-2 d-all`} style={{ width: '98%'}}>
      {/*<div className="card m-0 p-0" style={{ width: '100%' }}>
        <div className="card-body">
  <h5 className="card-title"></h5>*/}
            <div>
              <CheckBoxComponent tag = 'Solo anomali ' initialValue={false} setExternalValue={setFilterErr}/>
              <CheckBoxComponent tag = 'Solo in registro ' initialValue={false} setExternalValue={setFilterReg}/>
              <CheckBoxComponent tag = 'Solo da verificare ' initialValue={false} setExternalValue={setFilterTBV}/>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-dark" >
              <th>Data Ora</th>
              <th>Articolo</th>
              <th>Protocollo/ Task</th>
              <th>AI</th>
              <th>Overrides</th>
            </tr>

            <tr className="table-light" >
            <th><InputComponent initialValue={''} setExternalValue={setFilterMov}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterArt}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterProt}/></th>
            <th></th>
            <th>{/*
              <CheckBoxComponent tag = 'Solo anomali ' initialValue={false} setExternalValue={setFilterErr}/>
              <CheckBoxComponent tag = 'Solo in registro ' initialValue={false} setExternalValue={setFilterReg}/>
<CheckBoxComponent tag = 'Solo in da verificare ' initialValue={false} setExternalValue={setFilterTBV}/>*/}
              </th>
            </tr>

            </thead>
            <tbody>{rows}</tbody>
            </table>
            </div>
        </div>
  );

};

// Subcomponent
function SubComponent({ key, baseElement, addToQueue, filterMov, filterArt, filterProt, filterErr, filterReg, filterTBV }) {

  const [additionalData, setAdditionalData] = useState({});
  const [additionalDataVisible, setAdditionalDataVisible] = useState(false);

//  const [filterMov, setFilterMov] = useState('');

  function switchAdditionalDataVisible () {
    setAdditionalDataVisible(!additionalDataVisible);
  }

  const fetchData = async () => {
      try {

        const API = process.env.REACT_APP_BACKEND_URL;
        var request = baseElement;

        if (request == {}) return;

        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken(), 
            },
          }

          axios.post(`${API}/api/movements/td1/`, request, options).then(
            response => {
//                setLoading(false);
//                console.log(response);
//                console.log("DATA", response.data.data);
                setAdditionalData(response.data.data);
            }
        ).catch(
            error => {
//                setLoading(false);
                console.log(error);
            }
        );

  
      } catch (error) {console.log('Error fetching data: '+ error)
      } finally {
        //setLoading(false);
      }
  };

  useEffect(() => {}, [additionalDataVisible]);
  useEffect(() => {}, [additionalData]);

  useEffect(() => {
    addToQueue(fetchData);
  }, [baseElement]);

  useEffect(() => {
  }, [filterMov]);

  useEffect(() => {
  }, [filterArt]);

  useEffect(() => {
  }, [filterProt]);

  useEffect(() => {
  }, [filterErr]);

  useEffect(() => {
  }, [filterReg]);

  useEffect(() => {
  }, [filterTBV]);

  var visible = true;

  if (!(filterMov == '')) if 
    (!(
      (baseElement && baseElement.hasOwnProperty('username') && baseElement.username && baseElement.username.toUpperCase().includes(filterMov.toUpperCase())) 
      || (baseElement && baseElement.hasOwnProperty('timestamp') && baseElement.timestamp && baseElement.timestamp.toUpperCase().includes(filterMov.toUpperCase()))
      || (baseElement && baseElement.hasOwnProperty('movement_type_code') && baseElement.movement_type_code && baseElement.movement_type_code.toUpperCase().includes(filterMov.toUpperCase()))
      || (baseElement && baseElement.hasOwnProperty('stock_location_area') && baseElement.stock_location_area && baseElement.stock_location_area.toUpperCase().includes(filterMov.toUpperCase()))
    )) 
      visible = false;
  if (!(filterArt == '')) if 
      (!(
        (baseElement && baseElement.hasOwnProperty('item_code') && baseElement.item_code && baseElement.item_code.toUpperCase().includes(filterArt.toUpperCase())) 
        || (baseElement && baseElement.hasOwnProperty('item_desc') && baseElement.item_desc && baseElement.item_desc.toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('NC') && additionalData.NC && additionalData.NC.toUpperCase().includes(filterArt.toUpperCase()))

      )) 
        visible = false;
  if (!(filterProt == '')) if 
    (!(
        (baseElement && baseElement.hasOwnProperty('protocol_id') && baseElement.protocol_id && baseElement.protocol_id.toUpperCase().includes(filterProt.toUpperCase()))
        || (baseElement && baseElement.hasOwnProperty('protocol_type_code') && baseElement.protocol_type_code && baseElement.protocol_type_code.toUpperCase().includes(filterProt.toUpperCase()))

    
      )) 
  visible = false;

  if (filterErr == true) if (!additionalData.hasOwnProperty('reg_overrides') || !additionalData.reg_overrides.hasOwnProperty('is_error')    ||  additionalData.reg_overrides.is_error != 1) visible = false;
    if (filterReg == true) if (!additionalData.hasOwnProperty('reg_overrides') || !additionalData.reg_overrides.hasOwnProperty('in_registro') ||  additionalData.reg_overrides.in_registro != 1) visible = false;
    if (filterTBV == true) if (additionalData.hasOwnProperty('reg_overrides') && additionalData.reg_overrides.hasOwnProperty('verified') &&  additionalData.reg_overrides.verified == 1) visible = false;
    
//    if (additionalData.hasOwnProperty('reg_overrides') && additionalData.reg_overrides.hasOwnProperty('verified'))
 //   console.log("VERIFIED", filterTBV, additionalData.hasOwnProperty('reg_overrides'), additionalData.reg_overrides.hasOwnProperty('verified'), additionalData.reg_overrides.verified == 1)


  if (!visible) return <></>;


  var bgcol = "white";
  var color = "black";
  var link = <></>;

  var kg;
  if (additionalData.quantity_kg == null) kg = <></>;
  else kg = <>{additionalData.quantity_kg} kg</>

  var in_registro;
  if (additionalData.in_registro == "1") in_registro = <><FontAwesomeIcon icon={faCheck} /> {additionalData.in_registro_ai}  {additionalData.DataMov_ADM_ai}</>;
  else in_registro = <>{additionalData.in_registro_ai} {additionalData.DataMov_ADM_ai}</>;

  var color = "black";
  if (additionalData.quantity_kg < 0) color = "red";

  if (baseElement.protocol_type_code == 'moina' && !(additionalData.task_id == null)) 
    {
      var _link = "https://www.mechfluids.com/cdg/cgen/tasks/tasks_pdf_ajax.php?task_id=";
      link = <><Link to={_link+additionalData.task_id} style={{ textDecoration: 'none', color: "blue" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} /></Link> </>;
    }  
  else if (baseElement.protocol_type_code == 'moiddt' && !(additionalData.task_id == null))
  {
    var _link = "https://www.mechfluids.com/cdg/cgen/tasks/tasks_pdf_ajax.php?rdc=moiddt_ddt&task_id=";
    link = <><Link to={_link+additionalData.task_id} style={{ textDecoration: 'none', color: "brown" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} /></Link> </>;  
  }

  var bgcol = "";
  
  if (!(baseElement.ADM_olluda === '' || baseElement.ADM_olluda == null ) && !(baseElement.protocol_type_code == "moidp" )) bgcol = '#F5DEB3';
  if (!(baseElement.ADM_energetici == '' || baseElement.ADM_energetici == null) && !(baseElement.protocol_type_code == "moidp" )) bgcol = '#87CEFA';
  if (!(baseElement.ADM_alcoli == '' || baseElement.ADM_alcoli == null) && !(baseElement.protocol_type_code == "moidp" )) bgcol = '#FFB6C1';

    if (baseElement.ADM_olluda === "BG_PFSIC" || baseElement.ADM_energetici === "BG_PFEN")
      if (baseElement.protocol_type_code === "moina" ) 
          if (additionalData.hasOwnProperty('task_infos'))
            if (additionalData.task_infos.diba_altri_registri === 0)    
              if (additionalData.task_infos.hasOwnProperty('task_type_code'))
                  if (additionalData.task_infos.task_type_code === 7) bgcol = "";

  if (additionalData.hasOwnProperty('reg_ai') && additionalData.hasOwnProperty('reg_overrides'))
      if (!(additionalData.reg_ai.in_registro_ai === 1 || additionalData.reg_overrides.in_registro === 1)) bgcol='';               

  var list1 = [
    {'name': 'verified', 'type': 'bool'},
    {'name': 'is_error', 'type': 'bool'},
    {'name': 'notes_ADM', 'type': 'text'},
    {'name': 'in_registro', 'type': 'bool'},
  ];      

  var list2 = [
    {'name': 'DataMov_ADM', 'type': 'date'},
    {'name': 'TipoDoc', 'type': 'text'},
    {'name': 'NumDoc', 'type': 'text'},
    {'name': 'NumIntDoc', 'type': 'text'},
    {'name': 'DataDoc', 'type': 'date'},
    {'name': 'counterpart_desc_ADM', 'type': 'text'},
    {'name': 'PIVA_ADM', 'type': 'text'},
    {'name': 'protocol_id_ADM', 'type': 'text'},
    {'name': 'quantity_kg_ADM', 'type': 'number'},
  ];      

  /*
  if (additionalData.hasOwnProperty('reg_ai') && additionalData.hasOwnProperty('reg_overrides'))
  if (additionalData.reg_ai.in_registro_ai === 1 || additionalData.reg_overrides.in_registro === 1 || true)
  list = [
    {'name': 'verified', 'type': 'bool'},
    {'name': 'in_registro', 'type': 'bool'},
    {'name': 'is_error', 'type': 'bool'},
    {'name': 'DataMov_ADM', 'type': 'date'},
    {'name': 'TipoDoc', 'type': 'text'},
    {'name': 'NumDoc', 'type': 'text'},
    {'name': 'NumIntDoc', 'type': 'text'},
    {'name': 'DataDoc', 'type': 'date'},
    {'name': 'counterpart_desc_ADM', 'type': 'text'},
    {'name': 'PIVA_ADM', 'type': 'text'},
    {'name': 'protocol_id_ADM', 'type': 'text'},
    {'name': 'quantity_kg_ADM', 'type': 'number'},
  ];      
*/
    var _link_to_item = "https://www.mechfluids.com/cdg/cgen/ims/ims_control_panel.php?codice_art_cometa=";

      return (
    <>
    <tr key = {key}>
    <td>
      <p className = "my-0">{baseElement.timestamp}</p>
      <p className = "my-0">{baseElement.username} {baseElement.movement_notes}</p>
      <p className = "my-0">{baseElement.movement_type_code} {baseElement.quantity_value} {baseElement.quantity_unit_of_measure}</p>
      <p className = "my-0">{baseElement.branch_code} {baseElement.stock_location_area}-{baseElement.stock_location_x}-{baseElement.stock_location_y}</p>
    </td>
    <td>
      <p className = "my-0">{<><Link to={_link_to_item+baseElement.item_code} style={{ textDecoration: 'none', color: "black" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCaretRight} /></Link></>} {baseElement.item_code}</p>
      <p className = "my-0"></p>{baseElement.item_desc}
      <p className = "my-0">{baseElement.ADM_olluda} {baseElement.ADM_energetici} {baseElement.ADM_alcoli} {additionalData.percent_taxed_ADM ? <>({additionalData.percent_taxed_ADM}%)</>: null}</p>
      <p className = "my-0">NC {additionalData.NC} {additionalData.NCGroup ? <>({additionalData.NCGroup})</>: null}</p>
      <p className = "my-0" style={{ color: color}}>{kg}</p>
    </td>
    <td style={{ backgroundColor: bgcol}}>
      <p className = "my-0">{baseElement.protocol_id} {baseElement.protocol_type_code} {link}</p>
      <p className = "my-0">{additionalData.task_id} {/*obj.task_type_code*/}</p>
      <p className = "my-0"><pre>{JSON.stringify(additionalData.task_infos, null, 2)}</pre></p>
    </td>

    <td style={{ backgroundColor: bgcol}}>
      <p className = "my-0"><pre>{additionalData.hasOwnProperty('reg_ai') ? JSON.stringify(additionalData.reg_ai, null, 2) : null}</pre></p>
    </td>    
    <td style={{ backgroundColor: bgcol}}>

    { 
        list1.map( item => (additionalData.hasOwnProperty('reg_overrides') && additionalData.reg_overrides.hasOwnProperty(item.name) ?
        <p className = "my-0"><TextFieldUpdater type = {item.type} element = {baseElement} fieldName = {item.name} currentValue = {additionalData.reg_overrides[item.name]}/></p> 
        : <></>)) 
      }
    {<p className = "my-0"><FontAwesomeIcon icon={additionalDataVisible ? faMinus : faPlus} onClick={switchAdditionalDataVisible} style={{cursor: 'pointer'}} /></p>}

      { 
        additionalDataVisible &&  (list2.map( item => (additionalData.hasOwnProperty('reg_overrides') && additionalData.reg_overrides.hasOwnProperty(item.name) ?
        <p className = "my-0"><TextFieldUpdater type = {item.type} element = {baseElement} fieldName = {item.name} currentValue = {additionalData.reg_overrides[item.name]}/></p> 
        : <></>))) 
      }
      {/*additionalDataVisible && (<p className = "my-0"><pre>{additionalData.hasOwnProperty('reg_overrides') ? JSON.stringify(additionalData.reg_overrides, null, 2) : null}</pre></p>)*/}
    </td>    


    {
    /*<td style= {{ backgroundColor: bgcol}}><p className = "my-0">{element.ADM_olluda}{element.ADM_energetici}{element.ADM_alcoli} {in_registro}</p><p className = "my-0" style={{ color: color}}>{kg}</p></td>
    <td style={{ backgroundColor: bgcol}}><p className = "my-0">{element.protocol_id}</p><p className = "my-0">{obj.protocol_type_code} {link}</p></td>
    <td style={{ backgroundColor: bgcol}}><p className = "my-0">{obj.task_id} {obj.task_type_code}</p><p className = "my-0">{JSON.stringify(obj.diba_info)}</p></td>
    */}

    </tr>
    </>
  );



};

const Movements = () => {

    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(endDate.getTime()  -1000 * 60 * 60 * 24 * 1));
    const [obj, setObj] = useState({});
    const [moidp, setMoidp] = useState(true);
  
    const { checkPageAuthorization } = useAuthorization();
    
    const [formData, setFormData] = useState({
      item_code: '',
      item_desc: '',
      branch_code: '',
      start_date: startDate.toLocaleString('sv').split(' ')[0],
      end_date: endDate.toLocaleString('sv').split(' ')[0],
      RootCode: '',
      protocol_id: '',
      protocol_type_code: '',
    });
  
    // Handle input changes and update state
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      // Perform actions with form data, such as sending it to a server
      console.log('Form submitted:', formData);
      setObj(formData);// You can also reset the form if needed
    };

    useEffect(() => {
  
    }, [formData]);
  
  
    return (
    
    <>
      <div><h1>Movimenti</h1></div>
      <div>
      <form onSubmit={handleSubmit}>
        <div>
      <label>Start Date:</label>
      <DatePicker
        selected={startDate}
        onChange={(date) => {setStartDate(date); formData.start_date = date.toLocaleString('sv').split(' ')[0]; setFormData({ ...formData, start_date: date.toLocaleString('sv').split(' ')[0] });}}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        placeholderText="Select start date"
        dateFormat="dd/MM/yyyy"
        />
      <label>End Date:</label>
      <DatePicker
        selected={endDate}
        onChange={(date) => {setEndDate(date); formData.end_date = date.toLocaleString('sv').split(' ')[0]; setFormData({ ...formData, end_date: date.toLocaleString('sv').split(' ')[0] });}}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Select end date"
        dateFormat="dd/MM/yyyy"
      />
        </div>
        <div>
          <label htmlFor="item_code">Codice articolo:</label>
          <input
            type="text"
            id="item_code"
            name="item_code"
            value={formData.item_code}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="item_desc">Nome articolo:</label>
          <input
            type="text"
            id="item_desc"
            name="item_desc"
            value={formData.item_desc}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="protocol_id">Protocollo:</label>
          <input
            type="text"
            id="protocol_id"
            name="protocol_id"
            value={formData.protocol_id}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="branch_code">Sede:</label>
          <select
            id="branch_code"
            name="branch_code"
            value={formData.branch_code}
            onChange={handleInputChange}
          >
            <option value="">Tutte</option>
            <option value="lallio">Lallio</option>
            <option value="cuggiono">Cuggiono</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div>
          <label htmlFor="RootCode">Registro:</label>
          <select
            id="RootCode"
            name="RootCode"
            value={formData.RootCode}
            onChange={handleInputChange}
          >
            <option value="">Tutti</option>
            <option value="-">Regolati</option>
            <option value="BG_MPSIC">BG_MPSIC</option>
            <option value="BG_PFSIC">BG_PFSIC</option>
            <option value="BG_ASSOLTA">BG_ASSOLTA</option>
            <option value="BG_ADDITIVI">BG_ADDITIVI</option>
            <option value="BG_PFEN">BG_PFEN</option>
            <option value="BG_MPEN">BG_MPEN</option>
            <option value="MI_GUA">MI_GUA</option>
            <option value="MI_RAGIA">MI_RAGIA</option>
            <option value="MI_ISOESANO">MI_ISOESANO</option>
            <option value="MI_D80">MI_D80</option>
            <option value="MI_ET94">MI_ET94</option>
            <option value="MI_ET96">MI_ET96</option>
            <option value="MI_MEIPA">MI_MEIPA</option>

            {/* Add more options as needed */}
          </select>
        </div>
        <div>
          <label htmlFor="protocol_type_code">Tipo Movimento:</label>
          <select
            id="protocol_type_code"
            name="protocol_type_code"
            value={formData.protocol_type_code}
            onChange={handleInputChange}
          >
            <option value="">Tutti</option>
            <option value="mppri">Entrate</option>
            <option value="moiddt">Uscite</option>
            <option value="moina">Lavorazioni</option>
            {/* Add more options as needed */}
          </select>
        </div>



{/*

        <div>
        <label>
        <input
          type="checkbox"
          checked={moidp}
          onChange={() =>{setMoidp(!moidp)}}
        />
        Mostra approntamenti
      </label>

      </div>
    */}

        <button type="submit">Submit</button>
        </form>
      </div>
      <CardMovimenti formData = {obj} moidp = {moidp}/>
    </>
    );


};

export default Movements;