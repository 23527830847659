import React, { useEffect, useState} from 'react';
import Select from 'react-select';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { getUser, getToken } from '../utils/common';

import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faCheck, faSquare, faCheckSquare, faX, faGears, faFilter } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


const Item = ({item, filterUserID, filterPermitCode}) => {

    var visible = true;

    if (!(filterUserID == '')) if (!item.UserID.includes(filterUserID)) visible = false;
    if (!(filterPermitCode == '')) if (!item.permit_code.includes(filterPermitCode)) visible = false;

    if (!visible) return <></>;
    return (
        <>
        <tr>
            <td>{item.FirstName} {item.LastName} [{item.UserID}]</td>
            <td><Update item = {item} FieldName = 'permit_code' FieldValue =  {item.permit_code}/></td>
            <td><Update item = {item} FieldName = 'permit_value' FieldValue =  {item.permit_value}/></td>
            <td><Delete item = {item} /></td>
        </tr>
        </>
    );
};

const InputComponent = ({initialValue, setExternalValue}) => {
    // State to hold the value of the input
    const [inputValue, setInputValue] = useState(initialValue);
  
    // Function to handle input change
    const handleChange = (event) => {
      setInputValue(event.target.value);
      setExternalValue(event.target.value);
    };
  
    return (
      <div>
        {/* Input component */}
        <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>
        <input
          type="text"
          value={inputValue} // Bind input value to state
          onChange={handleChange} // Call handleChange function on change
        />
        {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
      </div>
    );
};

const Insert = () =>  {

    const [UserID, setUserID] = useState('');
    const [PermitCode, setPermitCode] = useState('');
    const [PermitValue, setPermitValue] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleUserIDChange = (event) => {
      setUserID(event.target.value);
    };
  
    const handlePermitCodeChange = (event) => {
        setPermitCode(event.target.value);
      };
  
    const handlePermitValueChange = (event) => {
        setPermitValue(event.target.value);
      };
      
    useEffect(() => {
    }, [disabled]);
  
  
    
    const insert = async () => {
      var request =  { 
          UserID: UserID,
          permit_code: PermitCode,
          permit_value: PermitValue,
        };
  
        console.log(request)
        const API = process.env.REACT_APP_BACKEND_URL;
        const options = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(), 
            },
        }

        var url = `${API}/auth/access_control/insert/`;

        axios.post(url, request, options).then(
            response => {
//                console.log(response);
                window.location.reload(); 
            }).catch(error => {
                console.log(error);
                setErrorMessage(error.message);
            });
  };
  
  const contentFocused = (
    <><tr>
        <td><SelectUser setValue={setUserID}/></td>
        <td><input type="text"  value={PermitCode} onChange={handlePermitCodeChange} className="mx-1" disabled={disabled}/></td>
        <td><input type="text"  value={PermitValue} onChange={handlePermitValueChange} className="mx-1" disabled={disabled}/></td>
        <td>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </td>
</tr></>);

const contentNotFocused = (<><tr>
  <td><>
      <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
  </></td>
  <td></td>
  <td></td>
  <td></td>
</tr></>);


return (isFocused ? contentFocused : contentNotFocused);
  
};

const SelectUser = ({setValue}) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(null);

    const fetchList = async () => {
 
        const API = process.env.REACT_APP_BACKEND_URL;
        const request = {};
        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken(), 
            },
          }

        var url = `${API}/auth/users/`;

        axios.post(url, request, options).then(
            response => {
                console.log(response);
                setOptions(response.data.data); 
            }).catch(error => {
                console.log(error);
                setError(error.message);
            });
    };
  
    useEffect(() => { fetchList() }, []);
    
    const handleInputChange = (inputValue) => {
      setInputValue(inputValue);
    };
  
    const handleSelectOption = (selectedOption) => {
      setSelectedOption(selectedOption);
      setValue(selectedOption.value);
    };
    var opts = options.map((item) => {return {  'value': item.UserID, 'label': item.FirstName + ' ' +item.LastName }})
    if (error) return <><p style = {{color: 'red'}}>{error}</p></>; 

    return (<>   
            <Select
              options={opts}
              value={selectedOption}
              onChange={handleSelectOption}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              placeholder="Type to filter ..."
              isClearable
              isSearchable
            /></>
    );
};

const Delete = ({ item }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
  /* eslint-disable no-restricted-globals */
  if (!confirm('Confirm delete?')) return;
  /* eslint-enable no-restricted-globals */
    try {
      const user = getUser();
      const access = user.access;
      if (!access.hasOwnProperty('access_control')) return;
      if (!(access.access_control.split(',').includes('*') || access.access_control.split(',').includes('delete'))) return;

      const request = {
        UserID: item.UserID,
        permit_code: item.permit_code,
        permit_value: item.permit_value,
      };

      const API = process.env.REACT_APP_BACKEND_URL;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
        },
      };

      const url = `${API}/auth/access_control/delete/`;

      const response = await axios.post(url, request, options);
      // Optionally, handle success response here
 //     console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const Update = ({item, FieldName, FieldValue}) =>  {

  const user = getUser();
  const access = user.access;
  var isUpdatable = false;

  const [parameterValue, setParameterValue] = useState(FieldValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState(FieldValue);
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  useEffect(() => {setInitialValue(parameterValue);}, []);

  useEffect(() => {}, [disabled]);

  if (!access.hasOwnProperty('access_control')) return <></>;
  
  if (access.access_control.split(',').includes('*') || access.access_control.split(',').includes('read')) isUpdatable = true;

  if (!isUpdatable) return <>{FieldValue}</>;


  const updateParameter = async () => {
  
    /* eslint-disable no-restricted-globals */
    if (!confirm('Confirm update?')) return;
    /* eslint-enable no-restricted-globals */
      try {
        const user = getUser();
        const access = user.access;
        if (!access.hasOwnProperty('access_control')) return;
        if (!(access.access_control.split(',').includes('*') || access.access_control.split(',').includes('update'))) return;

        const request = {
          UserID: item.UserID,
          permit_code: item.permit_code,
          permit_value: item.permit_value,
          target_field: FieldName,
          target_value: parameterValue,
        };

        const API = process.env.REACT_APP_BACKEND_URL;
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
        };

        const url = `${API}/auth/access_control/update/`;
        console.log("request", request)
        const response = await axios.post(url, request, options);
//        console.log(response);
        window.location.reload();
      } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  }; // end update parameter  

  var o;
  if (!isFocused) o = <span  style = {{color: 'blue'}} onClick = {() => {setIsFocused(true)}}>{FieldValue}</span>;
  else {

    o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>

  }
  return (<>
      {o}
      {((parameterValue !== initialValue)) && isFocused && (<>
          <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          </>
      )}
      { isFocused && (<>
          <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          </>
      )}          
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );

};

const UsersAccess = () => {

    const history = useNavigate();

    try {
      const user = getUser();
      const access = user.access;
//      console.log(access);
      if (!access.hasOwnProperty('access_control')) history('/dashboard');
      if (!(access.access_control.split(",").includes("*") || access.access_control.split(",").includes("read"))) history('/dashboard');
    }
    catch (error) {console.log(error); history('/dashboard');}

    const [isLoading, setLoading] = useState(true);
//    const [content, setContent] = useState(null);
    const [data, setData] = useState([]);
  
    const [filterUserID, setFilterUserID] = useState('');
    const [filterPermitCode, setFilterPermitCode] = useState('');

    const fetchData = async () => {
  
    try {

        const API = process.env.REACT_APP_BACKEND_URL;
        const request = {};
        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken(), 
            },
          }

          axios.post(`${API}/auth/access_control/`, request, options).then(
            response => {
                setLoading(false);
                console.log(response);
                console.log("DATA", response.data.data);
                setData(response.data.data);
            }
        ).catch(
            error => {
                setLoading(false);
                console.log(error);
            }
        );

  
      } catch (error) {console.log('Error fetching data: '+ error)
      } finally {setLoading(false);}
  
    };
    
    // fetch data only on mount
    useEffect(() => {fetchData()}, []);

    var content = 
    <>
        <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
            <tr className="table-light" >
            <th>
                <p className='my-0'>UserID</p>
            </th>
            <th>
                <p className='my-0'>PermitCode</p>
            </th>
            <th>
                <p className='my-0'>PermitValue</p>
            </th>
            <th>Actions</th>
            </tr>
            <tr className="table-light" >
            <th><InputComponent initialValue={''} setExternalValue={setFilterUserID}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterPermitCode}/></th>
            <th></th>
            <th></th>
            </tr>
            <Insert />
            </thead>
            <tbody>{data.map((item)=><Item item = {item} filterUserID={filterUserID} filterPermitCode={filterPermitCode}/>)}</tbody>
        </table>

    </>;


    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  
    return (<>{isLoading ? loading : content }</>);

};

export default UsersAccess;
