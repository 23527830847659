import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate, Link } from 'react-router-dom';
import {getUser, getToken} from '../utils/common'
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Navbar = ({toggleSidebar, sidebarVisible}) => {
    
    const visible = getToken() ? true : false;  
    const user = getUser();
    const access = user && user.access;

    return (
      <>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <span onClick={toggleSidebar} className={`navbar-toggler-icon mx-2 ${visible ? 'd-all' : 'd-none'}`}></span>            
              <NavLink className="navbar-brand" to="/dashboard">Olluda</NavLink>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className={`nav-item`}>
                    <NavLink className={`nav-link ${!visible ? 'd-all' : 'd-none'}`} activeClassName="active" to="/login">Login</NavLink>
                  </li>
                  <li className={`nav-item ${visible ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/dashboard">Dashboard</NavLink>
                  </li>
                  <li className={`nav-item ${visible && access.hasOwnProperty('users') ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/users">Users</NavLink>
                  </li>
                  <li className={`nav-item ${visible && access.hasOwnProperty('movimenti') ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/movimenti">Movimenti</NavLink>
                  </li>
                  <li className={`nav-item ${visible && access.hasOwnProperty('energetici') ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/energetici">Energetici</NavLink>
                  </li>
                  <li className={`nav-item ${visible && access.hasOwnProperty('lavorazioni') ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/lavorazioni">Lavorazioni</NavLink>
                  </li>
                  <li className={`nav-item ${visible && access.hasOwnProperty('lavorazioni') ? 'd-all' : 'd-none'}`}>
                    <NavLink className="nav-link" activeClassName="active" to="/olluda">Olluda</NavLink>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
  
  
        {/*
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
    
                <span onClick={toggleSidebar} className="navbar-toggler-icon mr-5"></span>            
                <a className="navbar-brand mr-2" href="#">Logo</a>
  
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
  
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="nav-link" href={`/login?m=${sidebarVisible}`}>Login</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={`/energetici?m=${sidebarVisible}`}>Registri Energetici (giorno)</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={`/energetici/registri_per_periodo/?m=${sidebarVisible}`}>Registri Energetici (periodo)</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={`/movements?m=${sidebarVisible}`}>Movimenti</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={`/movements/lavorazioni?m=${sidebarVisible}`}>Lavorazioni</a>
                    </li>
  
                  </ul>
                </div>
              </div>
            </nav>
    */}
      </>
      );
  
  };
  
export default Navbar;  