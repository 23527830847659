// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    margin-bottom: 20px;
     /*flex-wrap: wrap;*/
}

.input-container {
    margin-right: 20px; /* Add some spacing between input fields */
    margin-bottom: 0px;
    /*flex: 1;  Take up equal space within the flex container */
}

.input-container label {
    display: block; /* Labels are displayed as block elements */
    margin-bottom: 5px; /* Add some spacing between label and input */
}

.form-container button {
    align-self: flex-end; /* Align button to the top of the container */
}`, "",{"version":3,"sources":["webpack://./src/olluda/Olluda.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;KAClB,mBAAmB;AACxB;;AAEA;IACI,kBAAkB,EAAE,0CAA0C;IAC9D,kBAAkB;IAClB,2DAA2D;AAC/D;;AAEA;IACI,cAAc,EAAE,2CAA2C;IAC3D,kBAAkB,EAAE,6CAA6C;AACrE;;AAEA;IACI,oBAAoB,EAAE,6CAA6C;AACvE","sourcesContent":[".form-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start; \n    margin-bottom: 20px;\n     /*flex-wrap: wrap;*/\n}\n\n.input-container {\n    margin-right: 20px; /* Add some spacing between input fields */\n    margin-bottom: 0px;\n    /*flex: 1;  Take up equal space within the flex container */\n}\n\n.input-container label {\n    display: block; /* Labels are displayed as block elements */\n    margin-bottom: 5px; /* Add some spacing between label and input */\n}\n\n.form-container button {\n    align-self: flex-end; /* Align button to the top of the container */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
