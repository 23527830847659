import {Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import RobotoBold from '../fonts/Roboto/Roboto-Bold.ttf';
import RobotoRegular from '../fonts/Roboto/Roboto-Regular.ttf';

var fontsize = 8;
var col_values = '6%'
var col_0 = '52%'
var col_1 = '14%'
var col_2 = '12%'
var col_3 = '26%'
var col_3_1 = '10%'
var col_3_2 = '6%'
var col_3_3 = '5%'
var col_3_4 = '5%'

Font.register({ family: 'Roboto', fonts: [{ src: RobotoRegular, fontWeight: 'normal' }, { src: RobotoBold, fontWeight: 'bold' }] });

const styles = StyleSheet.create({

    page: {
      flexDirection: 'row',
      backgroundColor: 'white',
      fontFamily: 'Roboto',
    },

    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },

    title: {
      fontSize: fontsize+4,
      textAlign: 'center',
      marginTop: 35,  
      marginBottom: 10,
      fontWeight: 'bold',      

    },

    text: {
      fontSize: fontsize+2,
      textAlign: 'left',
    },

    boldText: {
        fontWeight: 'bold',      
    },

    table: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#000',
        padding: 2,
    },

      tableRow: {
        width: '100%',
        flexDirection: 'row',
      },

      tableRowGrey: {
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#D3D3D3',
    },


});
  
  // React component to generate the PDF
  const RegistroGiornaliero = ({date, RootCode, elements, balance}) => {
    
    var carichi = [];
    var scarichi = [];

    var totale_carichi = {"NCG1": 0, "NCG2": 0, "NCG3": 0, "NCG4": 0, "NCG5": 0, "NCG6": 0, "NCG7": 0, "NCGA": 0 };
    var totale_scarichi = {"NCG1": 0, "NCG2": 0, "NCG3": 0, "NCG4": 0, "NCG5": 0, "NCG6": 0, "NCG7": 0, "NCGA": 0 };

    const CellElement = (width, textAlign, fontWeight, fontSize, border, text) => {
        
        var s = {}
        s.fontWeight = fontWeight;
        s.width = width;
        s.fontSize = fontSize

        if (border.indexOf("A") >= 0 || border.indexOf("Y") >= 0 || border.indexOf("T") >= 0) s.borderTopWidth = 1;
        if (border.indexOf("A") >= 0 || border.indexOf("Y") >= 0 || border.indexOf("B") >= 0) s.borderBottomWidth = 1;
        if (border.indexOf("A") >= 0 || border.indexOf("X") >= 0 || border.indexOf("L") >= 0) s.borderLeftWidth = 1;
        if (border.indexOf("A") >= 0 || border.indexOf("X") >= 0 || border.indexOf("R") >= 0) s.borderRightWidth = 1;
        
        return (<>
        <View style={s}>
            <Text style={{textAlign: textAlign, padding: 1}}>{text}</Text>
        </View>
        </>)
    };

    for (var e in elements) {

        var element = elements[e];
      
        if (element.NCGroup == "NCG1") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg); 
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG2") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg); 
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG3") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG4") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG5") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG6") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCG7") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 
        if (element.NCGroup == "NCGA") 
            if (element.quantity_kg >= 0) totale_carichi[element.NCGroup] += parseFloat(element.quantity_kg);
            else totale_scarichi[element.NCGroup] -= element.quantity_kg; 

        var r;

        var TipoDoc = element && element.TipoDoc ? element.TipoDoc : (element && element.hasOwnProperty('doc_type') ? element.doc_type : ''); 
        var NumDoc = element && element.NumDoc ? element.NumDoc : (element && element.hasOwnProperty('doc_number') ? element.doc_number : ''); 
        var DataDoc = element && element.DataDoc ? (element.DataDoc.split('-')[2] + '/' + element.DataDoc.split('-')[1] + '/' + element.DataDoc.split('-')[0].slice(-2)) : (element && element.hasOwnProperty('doc_date') ? (element.doc_date.split('-')[2] + '/' + element.doc_date.split('-')[1] + '/' + element.doc_date.split('-')[0].slice(-2)) : ''); 

        var piva = element.PIVA_ADM ? element.PIVA_ADM : element.counterpart_piva; 
        var counterpart = element.counterpart_desc_ADM ? element.counterpart_desc_ADM : element.counterpart_desc; 

        if (element.protocol_type_code == 'moina' && Object.keys(element.additional_info).length > 0) 
            {
            r = <>
            <View style={styles.tableRow}>
                {CellElement(col_1, 'left', '', fontsize, 'LT', TipoDoc + ' ' + NumDoc + ' ' + DataDoc)}
                {CellElement(col_2, 'left', '', fontsize, 'LT', element.item_code)}
                {CellElement(col_3_1, 'left', '', fontsize-1, 'LT', element.additional_info.target_item_code)}
                {CellElement(col_3_2, 'center', '', fontsize, 'LT', element.additional_info.target_NC)}
                {CellElement(col_3_3, 'center', '', fontsize, 'LT', element.additional_info.target_quantity_kg.toFixed(3))}
                {CellElement(col_3_4, 'center', '', fontsize, 'LT', Math.round(-100 * parseFloat(element.quantity_kg) / parseFloat(element.additional_info.target_quantity_kg)).toFixed(0)+ '%')}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG1" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG2" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG3" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG4" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG5" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG6" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG7" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LTR', element.NCGroup == "NCGA" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}        
            </View>                    
            </>
            } 
        else
            {
            r = <>
            <View style={styles.tableRow}>
                {CellElement(col_1, 'left', '', fontsize, 'LT', TipoDoc + ' ' + NumDoc + ' ' + DataDoc)}
                {CellElement(col_2, 'left', '', fontsize, 'LT', element.item_code)}
                {CellElement(col_3, 'left', '', fontsize, 'LT', piva + ' ' + counterpart)}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG1" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG2" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG3" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG4" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG5" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG6" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LT', element.NCGroup == "NCG7" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}
                {CellElement(col_values, 'center', '', fontsize, 'LTR', element.NCGroup == "NCGA" ? Math.abs(element.quantity_kg).toFixed(3).replace(".", ",") : "-")}        
            </View>        
        </>;
        }

     if (elements[e].quantity_kg >=0) carichi.push(r);
     else scarichi.push(r);

    }
    
    return (<>
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>{date.toLocaleString('it').split(',')[0]} ({RootCode})</Text>
          <Text style={styles.text}>
            <Text style={styles.boldText}>Gruppi NC</Text> G1: 27101221/25, G2: 27101943/46/47/48, G3: 27101921/25, G4: 27101929, G5: 29011000, G6: 3403, G7: 38249097
        </Text>
    
            <View style={styles.tableRow}>
                {CellElement('100%', 'center', '', fontsize, '', ' ')}
            </View>

            {/* Balance Header */}
            <View style={styles.tableRow}>
                {CellElement(col_0, 'center', 'bold', fontsize+1, '', ' ')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G1')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G2')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G3')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G4')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G5')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G6')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G7')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LRT', 'Altre')}
            </View>
            <View style={styles.tableRow}>
                {CellElement(col_0, 'left', 'bold', fontsize+2, 'B', 'Giacenze riportate da chiusura precedente (kg)')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG1 ? balance.NCG1.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG2 ? balance.NCG2.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG3 ? balance.NCG3.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG4 ? balance.NCG4.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG5 ? balance.NCG5.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG6 ? balance.NCG6.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG7 ? balance.NCG7.start.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LYR', balance.NCG0 ? balance.NCG0.start.toFixed(3).replace(".", ",") : null)}
            </View>

            <View style={styles.tableRow}>
                {CellElement('100%', 'center', '', fontsize, '', ' ')}
            </View>

            {/* Carichi */}

            <View style={styles.tableRowGrey}>
                {CellElement('100%', 'left', 'bold', fontsize+2, 'TLRB', 'Carichi (kg)')}
            </View>

            <View style={styles.tableRow}>
                {CellElement(col_1, 'left', 'bold', fontsize+1, 'L', 'Documento')}
                {CellElement(col_2, 'left', 'bold', fontsize+1, 'L', 'Articolo')}
                {CellElement(col_3, 'left', 'bold', fontsize+1, 'L', 'Controparte')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G1')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G2')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G3')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G4')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G5')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G6')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G7')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LR', 'Altre')}
            </View>

            {carichi}            

            <View style={styles.tableRow}>
                {CellElement(col_0, 'left', 'bold', fontsize+1, 'YL', 'Totale carichi')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG1.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG2.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG3.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG4.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG5.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG6.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_carichi.NCG7.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YLR', totale_carichi.NCGA.toFixed(3).replace(".", ","))}
            </View>

            <View style={styles.tableRow}>
                {CellElement('100%', 'center', '', fontsize, '', ' ')}
            </View>

            {/* Scarichi */}

            <View style={styles.tableRowGrey}>
                {CellElement('100%', 'left', 'bold', fontsize+2, 'TLRB', 'Scarichi (kg)')}
            </View>

            <View style={styles.tableRow}>
                {CellElement(col_1, 'left', 'bold', fontsize+1, 'L', 'Documento')}
                {CellElement(col_2, 'left', 'bold', fontsize+1, 'L', 'Articolo')}
                {CellElement(col_3, 'left', 'bold', fontsize+1, 'L', 'Controparte | Prodotto | NC | Kg | % |')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G1')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G2')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G3')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G4')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G5')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G6')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'L', 'G7')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LR', 'Altre')}
            </View>

            {scarichi}

            <View style={styles.tableRow}>
                {CellElement(col_0, 'left', 'bold', fontsize+1, 'YL', 'Totale scarichi')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG1.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG2.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG3.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG4.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG5.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG6.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YL', totale_scarichi.NCG7.toFixed(3).replace(".", ","))}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'YX', totale_scarichi.NCGA.toFixed(3).replace(".", ","))}
            </View>

            <View style={styles.tableRow}>
                {CellElement('100%', 'center', '', fontsize, '', ' ')}
            </View>

            {/* Balance Footer */}
            <View style={styles.tableRow}>
                {CellElement(col_0, 'center', 'bold', fontsize+1, '', ' ')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G1')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G2')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G3')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G4')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G5')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G6')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LT', 'G7')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LRT', 'Altre')}
            </View>
            <View style={styles.tableRow}>
                {CellElement(col_0, 'left', 'bold', fontsize+2, 'B', 'Giacenze da riportare ad apertura successiva (kg)')}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG1 ? balance.NCG1.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG2 ? balance.NCG2.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG3 ? balance.NCG3.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG4 ? balance.NCG4.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG5 ? balance.NCG5.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG6 ? balance.NCG6.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LY', balance.NCG7 ? balance.NCG7.end.toFixed(3).replace(".", ",") : null)}
                {CellElement(col_values, 'center', 'bold', fontsize+1, 'LYR', balance.NCG0 ? balance.NCG0.end.toFixed(3).replace(".", ",") : null)}
            </View>
          {/* 
          <Text style={styles.text}>{JSON.stringify(elements)}</Text>
          <Text style={styles.text}>{JSON.stringify(balance)}</Text>          
          */}
  
        </View>
      </Page>
    </Document>
    
    </>); 
};
  
  export default RegistroGiornaliero;