// HomePage.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate, Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFilePdf, faPlus, faMinus, faCaretRight, faFilter } from '@fortawesome/free-solid-svg-icons';
import { BlobProvider } from '@react-pdf/renderer';
import { PDFDocument} from 'pdf-lib';
import {getToken} from '../utils/common.js'
import { Spinner } from 'react-bootstrap';

import { useAuthorization } from '../functions/authorization.js';

import VIAUpdater from '../updaters/VIAupdate.js';
import TextFieldUpdater from '../updaters/textfield.js';
import VIA from  '../olluda/VIAdocumentPDF.js' 

const InputComponent = ({initialValue, setExternalValue}) => {
  // State to hold the value of the input
  const [inputValue, setInputValue] = useState(initialValue);

  // Function to handle input change
  const handleChange = (event) => {
    setInputValue(event.target.value);
    setExternalValue(event.target.value);
  };

  return (
    <div>
      {/* Input component */}
      <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>
      <input
        type="text"
        value={inputValue} // Bind input value to state
        onChange={handleChange} // Call handleChange function on change
      />
      {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
    </div>
  );
};

const CheckBoxComponent = ({tag, initialValue, setExternalValue}) => {
  // State to hold the value of the input
  const [inputValue, setInputValue] = useState(initialValue);

  // Function to handle input change
  const handleChange = (event) => {
    setInputValue(event.target.checked);
    //console.log(inputValue);
    setExternalValue(!inputValue);
  };

  return (
    <span className='mx-2'>
      {/* Input component 
      <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>*/}
      <input
        type="checkbox"
        checked={inputValue} // Bind input value to state
        onChange={handleChange} // Call handleChange function on change
      />
      <label className = 'mx-1 '><b>{tag}</b></label>
      {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
    </span>
  );
};

const LinkToVIA = ({baseElement, additionalData}) => {
  const [pdfDoc, setPdfDoc] = useState(null);

  const handleBlob = async (blob) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const arrayBuffer = reader.result;
      const pdfDocument = await PDFDocument.load(arrayBuffer);
      setPdfDoc(pdfDocument);
    };
    reader.readAsArrayBuffer(blob);
  };

  return (
    <BlobProvider document={<VIA baseElement = {baseElement} additionalData = {additionalData}/>} documentTitle="VIA.pdf">
      {({ blob }) => {
        if (!pdfDoc && blob) {
          handleBlob(blob);
        }

        return (
          <span className ="mx-3" >
            {pdfDoc && (<FontAwesomeIcon icon={faFilePdf} style = {{color: "red"}} onClick={async () => {
                // Modify the PDF document using pdf-lib
                 /*
                const firstPage = pdfDoc.getPages()[0];
               
                firstPage.drawText('Text added with pdf-lib', {
                  x: 50,
                  y: firstPage.getHeight() / 2,
                  size: 30,
                  color: rgb(0, 0, 0),
                });
                */

                // Save the modified PDF as bytes
                const modifiedPdfBytes = await pdfDoc.save();

                // Open the modified PDF in a new tab
                const pdfDataUri = URL.createObjectURL(new Blob([modifiedPdfBytes], { type: 'application/pdf' }));
                window.open(pdfDataUri, '_blank');
              }} />) }

          </span>
        );
      }}
    </BlobProvider>
  );
};

const CardLavorazioni = ({formData}) => {

  const navigate = useNavigate();
  const [elements, setElements] = useState({});
  const [rows, setRows] = useState([]);

  const [content, setContent] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [filterMov, setFilterMov] = useState('');
  const [filterArt, setFilterArt] = useState('');
  const [filterProt, setFilterProt] = useState('');
  const [filterVIA, setFilterVIA] = useState('');

  const [filterErr, setFilterErr] = useState(false);
  const [filterReg, setFilterReg] = useState(false);
  const [filterTBV, setFilterTBV] = useState(false);
  const [filterCalo, setFilterCalo] = useState(false);

  const [queue1, setQueue1] = useState([]);
  const [queue2, setQueue2] = useState([]);
  const [queue3, setQueue3] = useState([]);
  const [queue4, setQueue4] = useState([]);
  const [queue5, setQueue5] = useState([]);
  const [queue6, setQueue6] = useState([]);
  const [queue7, setQueue7] = useState([]);
  const [queue8, setQueue8] = useState([]);


  const [processing1, setProcessing1] = useState(false);
  const [processing2, setProcessing2] = useState(false);
  const [processing3, setProcessing3] = useState(false);
  const [processing4, setProcessing4] = useState(false);
  const [processing5, setProcessing5] = useState(false);
  const [processing6, setProcessing6] = useState(false);
  const [processing7, setProcessing7] = useState(false);
  const [processing8, setProcessing8] = useState(false);

  useEffect(() => {
    // Process Queue 1
    const processQueue1 = async () => {
      if (queue1.length > 0 && !processing1) {
        setProcessing1(true);
        try {
          const task = queue1[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 1:', error);
        } finally {
          setQueue1(prevQueue => prevQueue.slice(1));
          setProcessing1(false);
        }
      }
    };

    processQueue1();

  }, [queue1, processing1]);

  useEffect(() => {
    // Process Queue 2
    const processQueue2 = async () => {
      if (queue2.length > 0 && !processing2) {
        setProcessing2(true);
        try {
          const task = queue2[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 2:', error);
        } finally {
          setQueue2(prevQueue => prevQueue.slice(1));
          setProcessing2(false);
        }
      }
    };

    processQueue2();

  }, [queue2, processing2]);

  useEffect(() => {
    // Process Queue 3
    const processQueue3 = async () => {
      if (queue3.length > 0 && !processing3) {
        setProcessing3(true);
        try {
          const task = queue3[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 3:', error);
        } finally {
          setQueue3(prevQueue => prevQueue.slice(1));
          setProcessing3(false);
        }
      }
    };

    processQueue3();

  }, [queue3, processing3]);

  useEffect(() => {
    // Process Queue 4
    const processQueue4 = async () => {
      if (queue4.length > 0 && !processing4) {
        setProcessing4(true);
        try {
          const task = queue4[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 4:', error);
        } finally {
          setQueue4(prevQueue => prevQueue.slice(1));
          setProcessing4(false);
        }
      }
    };

    processQueue4();

  }, [queue4, processing4]);

  useEffect(() => {
    // Process Queue 5
    const processQueue5 = async () => {
      if (queue5.length > 0 && !processing5) {
        setProcessing5(true);
        try {
          const task = queue5[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 5:', error);
        } finally {
          setQueue5(prevQueue => prevQueue.slice(1));
          setProcessing5(false);
        }
      }
    };

    processQueue5();

  }, [queue5, processing5]);

  useEffect(() => {
    // Process Queue 6
    const processQueue6 = async () => {
      if (queue6.length > 0 && !processing6) {
        setProcessing6(true);
        try {
          const task = queue6[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 6:', error);
        } finally {
          setQueue6(prevQueue => prevQueue.slice(1));
          setProcessing6(false);
        }
      }
    };

    processQueue6();

  }, [queue6, processing6]);

  useEffect(() => {
    // Process Queue 7
    const processQueue7 = async () => {
      if (queue7.length > 0 && !processing7) {
        setProcessing7(true);
        try {
          const task = queue7[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 7:', error);
        } finally {
          setQueue7(prevQueue => prevQueue.slice(1));
          setProcessing7(false);
        }
      }
    };

    processQueue7();

  }, [queue7, processing7]);

  useEffect(() => {
    // Process Queue 8
    const processQueue8 = async () => {
      if (queue8.length > 0 && !processing8) {
        setProcessing8(true);
        try {
          const task = queue8[0];
          await task();
        } catch (error) {
          console.error('Error processing task in Queue 8:', error);
        } finally {
          setQueue8(prevQueue => prevQueue.slice(1));
          setProcessing8(false);
        }
      }
    };

    processQueue8();

  }, [queue8, processing8]);

  const addToQueue1 = async (task) => { setQueue1(prevQueue => [...prevQueue, task]); };
  const addToQueue2 = async (task) => { setQueue2(prevQueue => [...prevQueue, task]); };
  const addToQueue3 = async (task) => { setQueue3(prevQueue => [...prevQueue, task]); };
  const addToQueue4 = async (task) => { setQueue4(prevQueue => [...prevQueue, task]); };
  const addToQueue5 = async (task) => { setQueue5(prevQueue => [...prevQueue, task]); };
  const addToQueue6 = async (task) => { setQueue6(prevQueue => [...prevQueue, task]); };
  const addToQueue7 = async (task) => { setQueue7(prevQueue => [...prevQueue, task]); };
  const addToQueue8 = async (task) => { setQueue8(prevQueue => [...prevQueue, task]); };

  const fetchData = async () => {
    setLoading(true);
    const Api = process.env.REACT_APP_BACKEND_URL;
    const Url = '/api/movements/lavorazioni/';

    try {
        var request = formData;
        if (request == {}) return;
        const response = await fetch(Api+Url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        });

        const data = await response.json();
  
        if (response.ok) {
//          console.log(data)
//          if (Object.keys(data).includes("error")) navigate('/login');
          setElements(data["data"]);
          console.log(data["data"])        
        } else {
//          navigate('/login');

            // Authentication failed, handle error
          console.log('Authentication failed');
        }
      } catch (error) {
        // Handle network or other errors
        console.log('An error occurred during authentication', error);
      } finally {setLoading(false);}
      
  };

  useEffect(() => {

    setElements({});
    setRows([]);
    setQueue1([]);
    setQueue2([]);
    setQueue3([]);
    setQueue4([]);
    setQueue5([]);
    setQueue6([]);
    setQueue7([]);
    setQueue8([]);

    fetchData();
  }, [formData]);

  useEffect(() => {

    var _rows = [];
    
    for (var e in elements) {
    
    if (e % 8 == 0)       _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue1}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);    
    else if (e % 8 == 1)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue2}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 2)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue3}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 3)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue4}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 4)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue5}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 5)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue6}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 6)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue7}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>);
    else if (e % 8 == 7)  _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue8}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterVIA = {filterVIA} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV} filterCalo={filterCalo}/>); 
    else   
    _rows.push(<SubComponent key = {e} baseElement =  {elements[e]} addToQueue={addToQueue1}  filterMov = {filterMov}  filterArt = {filterArt} filterProt = {filterProt} filterErr={filterErr} filterReg={filterReg} filterTBV={filterTBV}/>);

    }

    setRows(_rows);
  
  }, [elements, filterMov, filterArt, filterProt, filterErr, filterReg, filterTBV, filterVIA, filterCalo]);

  var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  if (isLoading) return (<>{loading}</>);
  
  return (
      <>
        <div className={`container-fluid p-1 mx-3 mt-2 d-all`} style={{ width: '98%'}}>
            <div>
              <CheckBoxComponent tag = 'Solo anomali ' initialValue={false} setExternalValue={setFilterErr}/>
              <CheckBoxComponent tag = 'Solo VIA ' initialValue={false} setExternalValue={setFilterReg}/>
              <CheckBoxComponent tag = 'Solo da verificare ' initialValue={false} setExternalValue={setFilterTBV}/>
              <CheckBoxComponent tag = 'Con calo ' initialValue={false} setExternalValue={setFilterCalo}/>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-dark" >
              <th>Protocollo/ DiBa</th>
              <th>Articolo/ NC target</th>
              <th>Status</th>
              <th>VIA</th>
              <th>Verifica</th>
            </tr>

            <tr className="table-light" >
            <th><InputComponent initialValue={''} setExternalValue={setFilterProt}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterArt}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterMov}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterVIA}/></th>
            <th></th>
            </tr>

            </thead>
          </table>
          </div>
          </div>

        {rows}</>
        );

};

// Subcomponent
function SubComponent({ key, baseElement, addToQueue, filterMov, filterArt, filterProt, filterVIA, filterErr, filterReg, filterTBV, filterCalo  }) {

  const [additionalData, setAdditionalData] = useState({});
  const [additionalDataVisible, setAdditionalDataVisible] = useState(false);

  function switchAdditionalDataVisible () {
    setAdditionalDataVisible(!additionalDataVisible);
  }

  const fetchData = async () => {

    const Api = process.env.REACT_APP_BACKEND_URL;
    const Url = '/api/movements/lavorazioni/details/';

    try {
        var request = baseElement;

        if (request == {}) return;

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        };

        const response = await fetch(Api+Url, options);

        const data = await response.json();
  
        if (response.ok) {
          setAdditionalData(data["data"]);
          console.log(data["data"]);
        } else {
            // Authentication failed, handle error
          console.log('Server response not ok');
        }
      } catch (error) {
        // Handle network or other errors
        console.log('An error occurred during authentication', error);
      }
      
  };

  useEffect(() => {}, [additionalDataVisible]);

  useEffect(() => {
    addToQueue(fetchData);
  }, []);

  useEffect(() => {
  }, [filterMov]);

  useEffect(() => {
  }, [filterArt]);

  useEffect(() => {
  }, [filterProt]);

  useEffect(() => {
  }, [filterErr]);

  useEffect(() => {
  }, [filterReg]);

  useEffect(() => {
  }, [filterTBV]);

  useEffect(() => {
  }, [filterVIA]);

  useEffect(() => {
  }, [filterCalo]);

  var visible = true;

  if (!(filterProt == '')) if 
    (!(
        (baseElement && baseElement.hasOwnProperty('protocol_id') && baseElement.protocol_id && baseElement.protocol_id.toUpperCase().includes(filterProt.toUpperCase()))
        || (baseElement && baseElement.hasOwnProperty('task_id') && baseElement.task_id && baseElement.task_id.toString().toUpperCase().includes(filterProt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('diba_code') && additionalData.diba_code && additionalData.diba_code.toString().toUpperCase().includes(filterProt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('task_type_name') && additionalData.task_type_name && additionalData.task_type_name.toString().toUpperCase().includes(filterProt.toUpperCase()))
    
      )) 
  visible = false;

  if (!(filterArt == '')) if 
    (!(
        (additionalData && additionalData.hasOwnProperty('target_item_desc') && additionalData.target_item_desc && additionalData.target_item_desc.toString().toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('target_item_code') && additionalData.target_item_code && additionalData.target_item_code.toString().toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('target_NC') && additionalData.target_NC && additionalData.target_NC.toString().toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('target_ADM_olluda') && additionalData.target_ADM_olluda && additionalData.target_ADM_olluda.toString().toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('target_ADM_energetici') && additionalData.target_ADM_energetici && additionalData.target_ADM_energetici.toString().toUpperCase().includes(filterArt.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('target_ADM_alcoli') && additionalData.target_ADM_alcoli && additionalData.target_ADM_alcoli.toString().toUpperCase().includes(filterArt.toUpperCase()))
    
      )) 
  visible = false;

  if (!(filterMov == '')) if 
    (!(
        (additionalData && additionalData.hasOwnProperty('task_status') && additionalData.task_status && additionalData.task_status.toString().toUpperCase().includes(filterMov.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('created_date') && additionalData.created_date && additionalData.created_date.toString().toUpperCase().includes(filterMov.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('completed_date') && additionalData.completed_date && additionalData.completed_date.toString().toUpperCase().includes(filterMov.toUpperCase()))
    
      )) 
  visible = false;

  if (!(filterVIA == '')) if 
    (!(
        (additionalData && additionalData.hasOwnProperty('VIA') && additionalData.VIA && additionalData.VIA.hasOwnProperty('VIA_number') && additionalData.VIA.VIA_number && additionalData.VIA.VIA_number.toString().toUpperCase().includes(filterVIA.toUpperCase()))
        || (additionalData && additionalData.hasOwnProperty('VIA') && additionalData.VIA && additionalData.VIA.hasOwnProperty('VIA_date') && additionalData.VIA.VIA_date && additionalData.VIA.VIA_date.toString().toUpperCase().includes(filterVIA.toUpperCase()))
    
      )) 
  visible = false;

  if (filterErr == true) if (!baseElement.hasOwnProperty('VIA') || !baseElement.VIA.hasOwnProperty('is_error')    ||  baseElement.VIA.is_error != 1) visible = false;
    if (filterReg == true) if (!baseElement.hasOwnProperty('VIA') || !baseElement.VIA.hasOwnProperty('is_VIA') ||  baseElement.VIA.is_VIA != 1) visible = false;
    if (filterTBV == true) if (baseElement.hasOwnProperty('VIA') && baseElement.VIA.hasOwnProperty('is_verified') &&  baseElement.VIA.is_verified == 1) visible = false;
    if (filterCalo == true) if (additionalData && additionalData.hasOwnProperty('balance_quantity_kg') && Math.round(additionalData.balance_quantity_kg * 1000) == 0.0) visible = false; else console.log(additionalData.balance_quantity_kg);
    
  if (!visible) return <></>;

  var movements = additionalData.hasOwnProperty('movements') ? additionalData.movements.map(item => {
  
  var bgcol = '';
  if ((item.ADM_olluda !== '' && item.ADM_olluda !== null) && (item.hasOwnProperty('in_registro') && (item.in_registro ==='1' || item.in_registro_ai ==='1') )) bgcol = '#F5DEB3';
  else if ((item.ADM_energetici !== '' && item.ADM_energetici !== null) && (item.hasOwnProperty('in_registro')  && (item.in_registro ==='1' || item.in_registro_ai ==='1' )))  bgcol = '#87CEFA';
  else if ((item.ADM_alcoli !== '' && item.ADM_alcoli !== null) && (item.hasOwnProperty('in_registro') && (item.in_registro ==='1'  || item.in_registro_ai ==='1' )))  bgcol = '#FFB6C1';
  
  var _link_to_item = "https://mechfluids.com/cdg/cgen/ims/ims_control_panel.php?codice_art_cometa=";

  return (  
  <>
  <tr>
  <td style={{backgroundColor: bgcol}}>{item.timestamp} {item.branch_code} {item.movement_type_code} {item.username}</td>
  <td style={{backgroundColor: bgcol}}>{<><Link to={_link_to_item+item.item_code} style={{ textDecoration: 'none', color: "black" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCaretRight} /></Link></>} {item.item_code}   
</td>
  <td style={{backgroundColor: bgcol}}>{item.item_desc}</td>
  <td style={{backgroundColor: bgcol}}>{item.NC}</td>
  <td style={{backgroundColor: bgcol}}>{item.ADM_olluda}{item.ADM_energetici}{item.ADM_alcoli}</td>
  <td style={{backgroundColor: bgcol}}>{item.quantity_kg}</td>
  <td style={{backgroundColor: bgcol}}>{item.percent_taxed && item.percent_taxed > 0 ? item.percent_taxed : null}</td>
  {/*
  <td style={{backgroundColor: bgcol}}>{item.hasOwnProperty('in_registro_ai') ? item.in_registro_ai : null} {item.hasOwnProperty('in_registro') ? item.in_registro : null}</td>
  */}
  <td style={{backgroundColor: bgcol}}>
    {(item.ADM_olluda || item.ADM_energetici || item.ADM_alcoli) && 
      <><p className = "my-0">
        <TextFieldUpdater type = 'bool' element = {item} fieldName = 'verified' currentValue = {item.hasOwnProperty('verified') ? item.verified : null}/>
        <TextFieldUpdater type = 'bool' element = {item} fieldName = 'in_registro' currentValue = {item.hasOwnProperty('in_registro') ? item.in_registro : null}/>
        <TextFieldUpdater type = 'number' element = {item} fieldName = 'quantity_kg_ADM' currentValue = {item.hasOwnProperty('quantity_kg_ADM') ? item.quantity_kg_ADM : null}/>
        <TextFieldUpdater type = 'number' element = {item} fieldName = 'percent_taxed_mov_ADM' currentValue = {item.hasOwnProperty('percent_taxed_mov_ADM') ? item.percent_taxed_mov_ADM : null}/>
        <TextFieldUpdater type = 'text' element = {item} fieldName = 'notes_ADM' currentValue = {item.hasOwnProperty('notes_ADM') ? item.notes_ADM : null}/>        
        </p>
      </>}   
      {!(item.ADM_olluda || item.ADM_energetici || item.ADM_alcoli) && 
      <><p className = "my-0">
        <TextFieldUpdater type = 'bool' element = {item} fieldName = 'verified' currentValue = {item.hasOwnProperty('verified') ? item.verified : null}/>
        <TextFieldUpdater type = 'text' element = {item} fieldName = 'notes_ADM' currentValue = {item.hasOwnProperty('notes_ADM') ? item.notes_ADM : null}/>        
        </p>
      </>}   

  </td>

  </tr>
  
  </>)}) : null;

  var VIAcolor = additionalData.hasOwnProperty("VIA") && additionalData.VIA.is_VIA == '1' ? 'brown': 'white';
  var _link = "https://mechfluids.com/cdg/cgen/tasks/tasks_pdf_ajax.php?task_id=";
  var _link_to_item = "https://mechfluids.com/cdg/cgen/ims/ims_control_panel.php?codice_art_cometa=";

//  if (additionalData) console.log(additionalData)
//  if (additionalData && additionalData.hasOwnProperty('VIA')) console.log(additionalData.VIA)
//  if (additionalData && additionalData.hasOwnProperty('VIA') && additionalData.hasOwnProperty('notes')) console.log(additionalData.VIA.notes)

  return (
      <>
      <div className={`container-fluid p-1 mx-3 mt-2 ${true ? 'd-all' : 'd-none'}`} style={{ width: '98%', backgroundColor: VIAcolor}}>
         <div className="card m-0 p-0" style={{ width: '100%' }}>
          <div className="card-body">
          
            <div>
            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-dark" >
                <th>Protocollo</th>
                <th>Articolo</th>
                <th>Quantità</th>
                <th>Status</th>
                <th>VIA</th>
                <th>Verifica</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td> 
                  <p className = "my-0">
                    {baseElement.protocol_id}{' '}
                    {<><Link to={_link+additionalData.task_id} style={{ textDecoration: 'none', color: "blue" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFilePdf} /></Link></>}  
                  </p>
                  <p className = "my-0">{additionalData.task_id}</p>            
                  <p className = "my-0">{additionalData.target_branch_code}</p>            
                  <p className = "my-0">DiBa: {additionalData.diba_code}</p>
                  <p className = "my-0">{additionalData.task_type_name}</p>
                </td>
                
                <td>
                  <p className = "my-0">[{<><Link to={_link_to_item+additionalData.target_item_code} style={{ textDecoration: 'none', color: "black" }} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCaretRight} /></Link></>} {additionalData.target_item_code}] {additionalData.target_item_desc}</p>
                  <p className = "my-0">NC: {additionalData.target_NC} {additionalData.target_ADM_olluda}{additionalData.target_ADM_energetici}{additionalData.target_ADM_alcoli} </p>
                  <p className = "my-0">% tassato: {additionalData.target_percent_taxed}</p>
                  <p className = "my-0">% tassato ADM: {additionalData.target_percent_taxed_ADM}</p>
                </td>
                <td>
                  <p className = "my-0">Prodotto: {additionalData.hasOwnProperty('target_quantity_kg') ? additionalData.target_quantity_kg.toFixed(3) : null} kg</p>
                  <p className = "my-0">Componenti: {additionalData.hasOwnProperty('components_quantity_kg') ? additionalData.components_quantity_kg.toFixed(3) : null} kg</p>
                  <p className = "my-0">Eccedenza [calo]: {additionalData.hasOwnProperty('balance_quantity_kg') ? additionalData.balance_quantity_kg.toFixed(3) : null} kg</p>
                </td>
                <td>
                  <p className = "my-0">Stato attuale: {additionalData.task_status}</p>
                  <p className = "my-0">Creato il {additionalData.created_date}</p>
                  <p className = "my-0">Completato il {additionalData.completed_date}</p>
                </td>
                <td>
                  <p className = "my-0">
                    {additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'bool' desc = 'VIA: ' element = {baseElement} fieldName = 'is_VIA' currentValue = {additionalData.VIA.is_VIA}/> : null}, {additionalData.hasOwnProperty("VIA") ? additionalData.VIA.is_VIA_ai: null}
                    {additionalData.hasOwnProperty("VIA") && additionalData.VIA.is_VIA==1 && (<LinkToVIA baseElement= {baseElement} additionalData = {additionalData}/>)}
                  </p>                  
                  {additionalData.hasOwnProperty("VIA") && additionalData.VIA.is_VIA && (<>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'text' desc = 'Numero ' element = {baseElement} fieldName = 'VIA_number' currentValue = {additionalData.VIA.VIA_number}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'date' desc = 'Data ' element = {baseElement} fieldName = 'VIA_date' currentValue = {additionalData.VIA.VIA_date}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'bool' desc = 'Calo in registro ' element = {baseElement} fieldName = 'calo_in_registro' currentValue = {additionalData.VIA.calo_in_registro}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'number' desc = 'Calo (kg) ' element = {baseElement} fieldName = 'Q_calo_ADM' currentValue = {additionalData.VIA.Q_calo_ADM}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'number' desc = '% tax calo ' element = {baseElement} fieldName = 'calo_percent_taxed' currentValue = {additionalData.VIA.calo_percent_taxed}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'text' desc = 'Note ' element = {baseElement} fieldName = 'notes' currentValue = {baseElement.VIA.notes}/> : null}</p>
                  </>)}

                </td>
                <td>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'bool' desc = 'verificato' element = {baseElement} fieldName = 'is_verified' currentValue = {additionalData.VIA.is_verified}/> : null}</p>
                  <p className = "my-0">{additionalData.hasOwnProperty("VIA") ? <VIAUpdater type = 'bool' desc =  'anomalo' element = {baseElement} fieldName = 'is_error' currentValue = {additionalData.VIA.is_error}/> : null}</p>
                </td>

            </tr>
            </tbody>
            </table>
            </div>

            <div>
              <h5>Movimenti</h5>
            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-light" >
                <th>timestamp</th>
                <th>Codice</th>
                <th>Articolo</th>
                <th>NC</th>
                <th>Registro</th>
                <th>Quantità</th>
                <th>% tassato</th>
                <th>overrides</th>

            </tr>
            </thead>
            <tbody>
            {movements}
            </tbody>
            </table>
            </div>

            <p className = "my-0"><FontAwesomeIcon icon={additionalDataVisible ? faMinus : faPlus} onClick={switchAdditionalDataVisible} style={{cursor: 'pointer'}} /></p>
            {additionalDataVisible && (<><pre>{JSON.stringify(baseElement, null, 2)}</pre><pre>{JSON.stringify(additionalData, null, 2)}</pre></>)}
          </div>
        </div>
      </div>
      </>
     );

}

const Lavorazioni = () => {

    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(endDate.getTime()  -1000 * 60 * 60 * 24 * 1));
    const [obj, setObj] = useState({});

    const { checkPageAuthorization } = useAuthorization();
    
    const [formData, setFormData] = useState({
      item_code: '',
      item_desc: '',
      branch_code: '',
      start_date: startDate.toLocaleString('sv').split(' ')[0],
      end_date: endDate.toLocaleString('sv').split(' ')[0],
      RootCode: '',
      protocol_id: '',
    });
  
    // Handle input changes and update state
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      // Perform actions with form data, such as sending it to a server
      console.log('Form submitted:', formData);
      setObj(formData);// You can also reset the form if needed
    };

    useEffect(() => {
  
    }, [formData]);
  
  
    return (
    
    <>
      <div><h1>Lavorazioni</h1></div>
      <div>
      <form onSubmit={handleSubmit}>
        <div>
      <label>Start Date:</label>
      <DatePicker
        selected={startDate}
        onChange={(date) => {setStartDate(date); formData.start_date = date.toLocaleString('sv').split(' ')[0]; setFormData({ ...formData, start_date: date.toLocaleString('sv').split(' ')[0] });}}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        placeholderText="Select start date"
        dateFormat="dd/MM/yyyy"
        />
      <label>End Date:</label>
      <DatePicker
        selected={endDate}
        onChange={(date) => {setEndDate(date); formData.end_date = date.toLocaleString('sv').split(' ')[0]; setFormData({ ...formData, end_date: date.toLocaleString('sv').split(' ')[0] });}}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="Select end date"
        dateFormat="dd/MM/yyyy"
      />
        </div>
        <div>
          <label htmlFor="item_code">Codice articolo:</label>
          <input
            type="text"
            id="item_code"
            name="item_code"
            value={formData.item_code}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="item_desc">Nome articolo:</label>
          <input
            type="text"
            id="item_desc"
            name="item_desc"
            value={formData.item_desc}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="protocol_id">Protocollo:</label>
          <input
            type="text"
            id="protocol_id"
            name="protocol_id"
            value={formData.protocol_id}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="branch_code">Sede:</label>
          <select
            id="branch_code"
            name="branch_code"
            value={formData.branch_code}
            onChange={handleInputChange}
          >
            <option value="">Tutte</option>
            <option value="lallio">Lallio</option>
            <option value="cuggiono">Cuggiono</option>
            {/* Add more options as needed */}
          </select>
        </div>
        <div>
          <label htmlFor="RootCode">Registro:</label>
          <select
            id="RootCode"
            name="RootCode"
            value={formData.RootCode}
            onChange={handleInputChange}
          >
            <option value="">Tutti</option>
            <option value="-">Regolati</option>
            <option value="BG_MPSIC">BG_MPSIC</option>
            <option value="BG_PFSIC">BG_PFSIC</option>
            <option value="BG_ASSOLTA">BG_ASSOLTA</option>
            <option value="BG_ADDITIVI">BG_ADDITIVI</option>
            <option value="BG_PFEN">BG_PFEN</option>
            <option value="BG_MPEN">BG_MPEN</option>
            <option value="MI_GUA">MI_GUA</option>
            <option value="MI_RAGIA">MI_RAGIA</option>
            <option value="MI_ISOESANO">MI_ISOESANO</option>
            <option value="MI_D80">MI_D80</option>
            <option value="MI_ET94">MI_ET94</option>
            <option value="MI_ET96">MI_ET96</option>
            <option value="MI_MEIPA">MI_MEIPA</option>

            {/* Add more options as needed */}
          </select>
        </div>

        <button type="submit">Submit</button>
        </form>
      </div>
      <CardLavorazioni formData = {obj}/>
    </>
    );


};

export default Lavorazioni;