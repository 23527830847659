// EnergeticiPage.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import CardEnergetici from './energetici/card.js';
import { useAuthorization } from './functions/authorization.js';

const RegistriEnergetici = () => {

    const [date, setDate] = useState(new Date());
    const [error, setError] = useState('');

    const { checkPageAuthorization } = useAuthorization();

    return (
    
    <>
      <div><h1>Registri energetici giornalieri</h1></div>
      <div>
        <form>
        <div>
        <label>Date:</label>
        <DatePicker
          selected={date}
          onChange={(date) =>   {setDate(date);}}
          dateFormat="dd/MM/yyyy"
        />
          <button type="button" onClick={() => {setDate(new Date(date.getTime() + 60 * 60 * 24 * 1000)); }}>+</button>
          <button type="button" onClick={() => {setDate(new Date(date.getTime() - 60 * 60 * 24 * 1000)); }}>-</button>
        </div>
        </form>
      </div>
        <div><CardEnergetici date  = {new Date(date)} RootCode = "BG_MPEN" color = "green" /></div> 
        <div><CardEnergetici date  = {new Date(date)} RootCode = "BG_PFEN" color = "blue"/></div>
        <div><CardEnergetici date  = {new Date(date)} RootCode = "MI_GUA" color = "gray"/></div>
        <div><CardEnergetici date  = {new Date(date)} RootCode = "MI_RAGIA" color = "gray"/></div>
        <div><CardEnergetici date  = {new Date(date)} RootCode = "MI_D80" color = "gray"/></div>
        <div><CardEnergetici date  = {new Date(date)} RootCode = "MI_ISOESANO" color = "gray"/></div>       
    </>
    );


};

export default RegistriEnergetici;